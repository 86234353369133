import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import buyCable from '../../assets/images/buyCable.svg';
import cardOverlay from '../../assets/images/cardOverlay.svg';
import emptyView from '../../assets/images/emptyView.svg';
import payBills from '../../assets/images/payBills.svg';
import withdraw from '../../assets/images/send.png';
import sendMoney from '../../assets/images/sendMoney.svg';
import AppButton from '../../components/app_button/app_button';
// import { industries } from "../../mock/industries";
import { FormDropdown } from '../../components/form_dropdown/form_dropdown';
import { FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import { MerchantContentWrapper, MerchantGraph, MerchantGraphHeader, PayBillsAggregatedPop, PayUtilitiesAggregatedPop } from '../../components/merchant_widgets/merchant_widgets';
import { AccountInfoPopup, SetupBankPopupAlert } from '../../components/popup/popup';
import { MobileRedAlertWithLink } from '../../components/red_alert/red_alert';
import { useInput } from '../../hooks/use_input';
import usePopup from '../../hooks/use_popup';
import { useTransactions } from '../../hooks/use_transactions';
import useVerifiedUser from '../../hooks/use_user_verified';
import { fecthReferralDetails } from '../../redux/merchant/merchant.referral';
import {
  addMerchant,
  closeCreateMerchant,
  fetchBalance,
  fetchCashpoints,
  fetchCustomers,
  fetchFees,
  fetchIndustryList,
  fetchPayouts,
  fetchPrefix,
  fetchTransactions,
  fetchUserMerchant,
  fetchcodeFailure,
  fetchcodeSuccess,
  getBanks,
  getMerchantBeneficiary,
  openMessageRed,
  openTransferPopup,
} from '../../redux/merchant/merchant_actions';
import { store } from '../../redux/store';
import { fetchUser } from '../../redux/user/user_actions';
import { getCurrentUser, walletWithdraw } from '../../services/apiSevices';
import { formatNumber } from '../../utils/formatNumber';
import { getLGAFromState, mappedStates } from '../../utils/helper';
import { addBusinessValidate } from '../../utils/validation_rules/signup_validate';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import { MerchantCard } from '../cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import './index.css';
import { Transfer } from './transfer';
import { Withdraw } from './withdraw';

export default function MerchantDashboardPage() {
  const [showBankSetup, setShowBankSetup] = useState(false);

  const role = useSelector((state) => state.user.role);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const { mid } = useSelector((state) => state.merchant.merchantDetails);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const customers = useSelector((state) => state.merchant.customers);
  const transactions = useSelector((state) => state.merchant.transactions);
  const payouts = useSelector((state) => state.merchant.payouts);
  const balance = useSelector((state) => state.merchant.balance);
  const isWithdrawPopupOpen = useSelector((state) => state.merchant.isWithdrawPopupOpen);
  const openCreateBusiness = useSelector((state) => state.merchant.shouldCreateMerchant);
  const qrShow = useSelector((state) => state.merchant.openMerchantQR);
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();

  // const openRedMessage = useSelector((state) => state.merchant.openRedMessage);

  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false);
  const { onBillShow, onBillClose, billShow } = usePopup('Bill');
  const { onUtilityShow, onUtilityClose, utilityShow } = usePopup('Utility');

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPayouts(id));
  }, []);

  useEffect(() => {
    dispatch(fetchCustomers(id));
    dispatch(fetchBalance(id));
    dispatch(fetchcodeSuccess(key));
    dispatch(fetchcodeFailure(key));
    dispatch(fetchTransactions(id));

    dispatch(fetchCashpoints(id));
    dispatch(fetchUserMerchant(id));
    dispatch(getBanks());
    dispatch(fetchFees(key, id));
    dispatch(getMerchantBeneficiary(key));
    dispatch(fetchPrefix(key, id));
    closeCreateMerchant(dispatch);
    dispatch(fetchIndustryList());
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(fecthReferralDetails(mid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const res = await getCurrentUser();
        fetchUser(dispatch, res.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    };
    fetchCurrentUser();
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let total = useMemo(() => transactions?.reduce((cumm, curr) => (cumm += curr?.accountbalance), 0), [transactions]);

  const cards = [
    {
      id: 1,
      name: transactions ? `₦${formatNumber(total ?? 0)}` : '₦0.00',
      description: 'Total Recieved',
      background: '#AE207C',
    },
    {
      id: 2,
      name: balance ? `₦${formatNumber(balance ?? 0)}` : '₦0.00',
      description: 'ledger balance',
      background: '#E20E17',
    },
  ];
  const customerCards = [
    {
      id: 3,
      name: customers.Customers ? customers.Customers.length : 0,
      description: customers.Customers ? 'customer'.concat(customers.Customers.length > 1 ? 's' : '') : '',
      background: '#F79420',
    },
  ];

  const onWithdrawClick = () => {
    const bank = store.getState().merchant.merchantDetails?.bank;
    if (!bank) {
      openMessageRed(dispatch);
    } else {
      openTransferPopup(dispatch);
    }
  };

  const onTransferClick = () => {
    setIsTransferPopupOpen(true);
  };

  const { tnxData, todayTnxTotal } = useTransactions(transactions);

  const RenderBills = useCallback(
    ({ noMobile, className }) => (
      <>
        {role === 'merchant-finance' ? null : (
          <div className={`height-35-p bills-container ${className || ''} ${noMobile && 'no-mobile'}`}>
            <div className="d-flex flex-one">
              <BillItem onClick={onTransferClick} icon={sendMoney} title="transfer" />
              <Spacer width={5} />
              <BillItem onClick={onWithdrawClick} icon={withdraw} title="withdraw" />
            </div>
            <Spacer height={5} />
            <div className="d-flex flex-one">
              <BillItem onClick={onUtilityShow} icon={payBills} title="pay bills" />
              <Spacer width={5} />
              <BillItem onClick={onBillShow} icon={buyCable} title="airtime" />
            </div>
          </div>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const RenderTransactions = useCallback(
    ({ noMobile, className }) => (
      <div style={{ minHeight: '350px' }} className={`flex-one border-radius5 p-medium primary-bg-color pad-30 ${className || ''} ${noMobile && 'no-mobile'}`}>
        <p className="fw500 recent-clamp-font text-color">Your Recent Transactions</p>
        <Spacer height={30} />
        {payouts.length > 0 ? (
          payouts
            .filter((_, idx) => idx < 6)
            .map((payout, idx) => (
              <div key={idx}>
                <TransactionItem transaction={payout} />
                <Spacer height={25} />
              </div>
            ))
        ) : (
          <div className="full-height full-width d-flex align-center j-center flex-vertical">
            <img src={emptyView} alt="" />
            <Spacer height={20} />
            <p className="fw400 text-color font-size-17">No Transactions</p>
            <Spacer height={30} className="no-desktop-but-mobile" />
          </div>
        )}
        {payouts.length > 0 && (
          <div className="p-relative d-flex align-center j-center">
            <button onClick={() => history.push('/admin/merchant/history')} className="unstyle-button c-pointer action-color  fw400 font-size-15">
              See More
            </button>
          </div>
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  return (
    <MerchantContentWrapper className="d-flex flex-vertical fade-in" hasTopSpacing>
      {(hasBVNorNIN && isVerified) || hasBeenVerified ? '' : <MobileRedAlertWithLink text="Activate Your Account" lowerText="Verify Your Business Account To Enjoy All VPAY Features" />}
      {!openCreateBusiness && (
        <div className="d-flex flex-one">
          <div className="d-flex flex-vertical right-container p-relative width-70-p">
            <div className="d-flex no-mobile" style={{ height: '10em' }}>
              {cards.map((card, index) => (
                <Fragment key={card.id}>
                  <MerchantCard
                    icon={<img src={cardOverlay} />}
                    right="0"
                    background={card.background}
                    className="flex-one pl-40"
                    height="100%"
                    topText={card.name}
                    bottomText={card.description}
                    center
                    textWhite={true}
                    needsToggle={true}
                    isVisible={false}
                  />
                  {index < 2 && <Spacer width={10} />}
                </Fragment>
              ))}
              <MerchantCard
                icon={<img src={cardOverlay} />}
                right="0"
                background={customerCards[0].background}
                className="flex-one pl-40"
                height="100%"
                topText={customerCards[0].name}
                bottomText={customerCards[0].description}
                center
                textWhite={true}
                isVisible
              />
            </div>
            <div className="no-desktop-but-mobile" style={{ height: '19em' }}>
              <MerchantCard
                width="calc(100%)"
                background={cards[1].background}
                className="flex-one pl-40"
                height="9em"
                topText={cards[1].name}
                bottomText={cards[1].description}
                center
                textWhite={true}
                icon={<img src={cardOverlay} />}
                right="0"
                needsToggle={true}
                isVisible={false}
              />
              <Spacer height={10} />
              <MerchantCard
                width="calc(100%)"
                background={cards[0].background}
                className="flex-one pl-40"
                height="9em"
                topText={cards[0].name}
                bottomText={cards[0].description}
                center
                textWhite={true}
                icon={<img src={cardOverlay} />}
                right="0"
                needsToggle={true}
                isVisible={false}
              />
            </div>
            <Spacer height={10} className="no-desktop-but-mobile" />
            <Spacer height={20} className="no-mobile" />
            <RenderBills className="no-desktop" />
            <RenderTransactions className="no-desktop-but-mobile" />
            <Spacer height={30} className="no-desktop-but-mobile" />
            <div className="flex-one no-mobile border-radius5 primary-bg-color">
              <MerchantGraph data={tnxData}>
                <MerchantGraphHeader>
                  <p className="fw500 font-size-18 text-color">
                    {' '}
                    Daily Turnover <br />
                    {`₦${formatNumber(todayTnxTotal)}`}
                  </p>
                </MerchantGraphHeader>
              </MerchantGraph>
            </div>
          </div>
          <Spacer className="no-mobile" width={20} />
          <div className="d-flex no-mobile flex-vertical width-30-p">
            <RenderBills noMobile />
            {role !== 'merchant-finance' && <Spacer height={20} />}
            <RenderTransactions noMobile />
          </div>
        </div>
      )}
      {openCreateBusiness && <CreateMerchant />}
      {isWithdrawPopupOpen && <Withdraw withdrawService={walletWithdraw} balance={balance} />}
      {isTransferPopupOpen && <Transfer setIsTransferPopupOpen={setIsTransferPopupOpen} />}
      {billShow && <PayBillsAggregatedPop onClose={onBillClose} />}
      {utilityShow && <PayUtilitiesAggregatedPop onClose={onUtilityClose} />}
      {qrShow && <AccountInfoPopup />}
      {showBankSetup && <SetupBankPopupAlert onClose={() => setShowBankSetup(false)} />}
    </MerchantContentWrapper>
  );
}

export const TransactionItem = ({ transaction }) => (
  <div className="full-width  d-flex align-center">
    <img style={{ height: '30px' }} src={emptyView} alt="" />
    <Spacer width={20} />
    <p className="fw400 trans-snippet font-size-13">{transaction.narrative}</p>
  </div>
);

export const CreateMerchant = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [industry, setIndustry] = useState('');
  const [state, setState] = useState('');
  const [lga, setLga] = useState('');
  const [mappedLGA, setMappedLGA] = useState([]);

  const businessnameProps = useInput('', 'text', error, setError);
  const emailProps = useInput('', 'text', error, setError);
  const addressProps = useInput('', 'text', error, setError);
  const businessphoneProps = useInput('', 'text', error, setError, true);

  const userID = useSelector((state) => state.user.user['user']);
  const user = useSelector((state) => state.user.user);
  const isBusy = useSelector((state) => state.merchant.loading);
  const industries = useSelector((state) => state.merchant.industries);

  const data = {
    email: emailProps.value,
    businessname: businessnameProps.value,
    businessphone: businessphoneProps.value,
    industry,
    state,
    lga,
    address: addressProps.value,
    contactfirstname: user.firstname,
    contactlastname: user.lastname,
    referralcode: '',
    user: userID,
  };

  useEffect(() => {
    setMappedLGA(getLGAFromState(state));
  }, [state]);

  const onIndustryChange = (e) => {
    setIndustry(e);
  };

  const onStateChange = (e) => {
    setState(e);
  };

  const onLgaChange = (e) => {
    setLga(e);
  };

  const onClick = () => {
    const error = addBusinessValidate(data);
    setError(error);

    if (!/^[0-9A-Za-z_@-]+$/.test(data.contactfirstname) || !/^[0-9A-Za-z_@-]+$/.test(data.contactlastname)) {
      openNotificationWithIcon('warning', 'Warning', " Name must not include space and special characters except '-' ");
      return;
    }

    if ((!/^[A-Za-z]+$/.test(data.contactfirstname) && data.contactfirstname) || (!/^[A-Za-z]+$/.test(data.contactlastname) && data.contactlastname)) {
      openNotificationWithIcon('warning', 'Warning', 'Name must not include numbers');
      return;
    }

    if (data.businessphone.length !== 11 || data.businessphone[0] !== '0') {
      openNotificationWithIcon('warning', 'Warning', "Phone number must start with '0' and must be 11 characters");
      return;
    }

    if (Object.keys(error).length === 0) {
      dispatch(addMerchant(data));
    }
  };
  return (
    <div className="full-width d-flex align-center j-center flex-vertical">
      <Spacer height={20} />
      <p className="fw400 text-color font-size-25">Create New Business</p>
      <Spacer height={30} />
      <div className="primary-bg-color add-bis-wrapper p-medium">
        <div className="d-flex align-center mobile-flex-column">
          <FormInput label="First Name" disabled value={user.firstname} name="contactfirstname" className="create-cashier-input" />
          <Spacer width={10} />
          <FormInput label="Last Name" disabled value={user.lastname} className="create-cashier-input" name="contactlastname" />
        </div>
        <FormInput label="Email Address" name="email" {...emailProps} />
        <FormInput label="Business Name" name="businessname" {...businessnameProps} />
        <FormDropdown onChange={onIndustryChange} error={error} name="industry" options={industries} label="Business Industry" className="full-width" />
        <div className="d-flex align-center mobile-flex-column">
          <FormDropdown label="State" onChange={onStateChange} error={error} name="state" options={mappedStates} className="create-cashier-input" />
          <Spacer width={10} />
          <FormDropdown label="Lga" onChange={onLgaChange} error={error} options={mappedLGA} className="create-cashier-input" name="lga" />
        </div>
        <FormInput label="Address" name="address" {...addressProps} />
        <FormInput label="Phone Number" name="businessphone" {...businessphoneProps} />
      </div>
      <Spacer height={30} />
      <div className="add-cashier-btn-wrapper d-flex j-space-between align-center">
        <button onClick={() => closeCreateMerchant(dispatch)} className="cancel-btn text-color font-size-18 c-pointer">
          Cancel
        </button>
        <AppButton onClick={onClick} isActive={true} isBusy={isBusy} className="create-btn font-size-18 fw400 primary-color" name="Create" />
      </div>
      <Spacer height={70} />
    </div>
  );
};

const BillItem = ({ title, icon, onClick }) => {
  return (
    <button onClick={onClick} className="d-flex border-none border-radius5 primary-bg-color flex-one align-center j-center flex-vertical unstyled-button c-pointer">
      <img src={icon} alt={'Click to ' + title} />
      <Spacer height={5} />
      <p className="text-capitalize fw400 text-color">{title}</p>
    </button>
  );
};
