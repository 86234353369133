import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Webcam from 'react-webcam';
import { useTimer } from '../../hooks/use_timer';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { fetchUserMerchant } from '../../redux/merchant/merchant_actions';
import { addTeamMember, complianceVideoUpload, uploadFilesToS3 } from '../../services/apiSevices';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';

import { useQueryClient } from 'react-query';
import createGoogleRecaptchaV3 from '../../utils/createCaptchaToken';
import { getInLS, saveToLS } from '../../utils/local';
import './webcam.css';

// const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: 400,
  height: 380,
  facingMode: 'user',
};

export const WebcamCapture = ({ image, setImage, onSubmitClick, setImageFile, loading }) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(
    async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const file = new File([blob], 'image');
      // let newImage = await compressImage(file, COMPRESSOR_CONFIG);
      setImageFile(file);
      setImage(imageSrc);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [webcamRef],
  );

  const onClick = (e) => {
    e.preventDefault();
    capture();
  };

  return (
    <div className="webcam-container">
      <div className="webcam-img">
        {image === '' ? <Webcam audio={false} height={180} ref={webcamRef} screenshotFormat="image/jpeg" width={200} videoConstraints={videoConstraints} /> : <img alt="webcam" src={image} />}
      </div>
      <div>
        <Spacer height={10} />
        {image !== '' ? (
          <div className="d-flex align-center">
            {!loading && <AppButton isActive={true} onClick={() => setImage('')} className="webcam-btn fw500" name="Retake Photo" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={onSubmitClick} name="Upload" />
          </div>
        ) : (
          <button onClick={(e) => onClick(e)} className="webcam-btn fw500">
            Capture
          </button>
        )}
        <Spacer height={10} />
      </div>
    </div>
  );
};

export const WebcamVideoCapture = ({ setStage, onClose, liveliness_data }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);

  const webcamRef = useRef(null);
  const { time, startTimer, stopTimer } = useTimer(5);
  const { time: secondaryTimer, startTimer: secondaryStartTimer, stopTimer: secondaryStopTimer } = useTimer(6);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState('');
  const mediaRecorderRef = useRef(null);
  const [captured, setCaptured] = useState(false);
  const [displaying, setDisplaying] = useState(false);
  const recordedChunks = useRef([]);
  const stream = useRef(null);
  const [recording, setRecording] = useState(false);

  async function setUpCamera() {
    if (navigator) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: true,
      });
      webcamRef.current.srcObject = stream.current;
      setDisplaying(true);
      startTimer();
    }
  }

  const startRecording = () => {
    let options;
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
      options = { mimeType: 'video/webm; codecs=vp9' };
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      options = { mimeType: 'video/webm' };
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
    } else {
      openNotificationWithIcon('error', 'Error', 'Media Type cannot be played on this device');
    }
    if (displaying) {
      mediaRecorderRef.current = new MediaRecorder(stream.current, options);
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.onstop = doPreview;
      mediaRecorderRef.current.start(1000);
      setRecording(true);
    }
  };
  const doPreview = () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/mp4',
      });
      setVideoFile(URL.createObjectURL(blob));
    }
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      recordedChunks.current.push(data);
    }
  }, []);

  const stopRecording = () => {
    if (recording) {
      setVideo(recordedChunks.current);
      setVideoFile(recordedChunks.current);
      mediaRecorderRef.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
      setCaptured(true);
    }
  };

  useEffect(() => {
    setUpCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
      startRecording();
      secondaryStartTimer();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (secondaryTimer === 0) {
      secondaryStopTimer();
      stopRecording();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimer]);

  const closePopup = () => {
    dispatch(fetchUserMerchant(id));
    onClose();
  };

  const handleUpload = async () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/webm',
      });
      const file = new File([blob], 'video.mp4', {
        type: blob.type,
      });
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      // data.append('cloud_name', getCloudNameFromEnv());
      // data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
      // data.append('folder', 'PersonalIdentificationDocument');
      // data.append('api_key', getCloudKeyFromEnv());

      try {
        setLoading(true);
        const res = await uploadFilesToS3('LiveVideo', data);
        const videoUploadResponse = res?.data?.data?.file_url;
        if (videoUploadResponse) {
          const info = {
            video: videoUploadResponse,
            liveliness_key: liveliness_data?.liveliness_key,
          };
          try {
            const res = await complianceVideoUpload(info, key);
            if (res.data.status) {
              openNotificationWithIcon('success', 'Success', 'Video Uploaded Successfully');
              setLoading(false);
              setVideo('');
              setVideoFile(null);
              recordedChunks.current = [];
              setCaptured(false);
              closePopup();
            }
          } catch (e) {
            setLoading(false);
            openNotificationWithIcon('error', 'Error', e.response?.data?.message);
          }
        }
      } catch (error) {
        setLoading(false);
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  const retakeVideo = () => {
    setVideo('');
    setVideoFile(null);
    recordedChunks.current = [];
    setCaptured(false);
    setStage(0);
  };

  const isCameraStarted = time >= 1;
  const isRecordingStarted = time <= 0;
  const isStillRecording = secondaryTimer >= 1;

  return (
    <div className="p-relative">
      {!captured && <Webcam ref={webcamRef} audio={false} imageSmoothing={true} id="cam_video" className="video-info-wrapper full-width d-flex align-center j-center" />}
      {videoFile && captured && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      {isCameraStarted && <p className="fw400 action-color text-center font-size-15 text-capitalize">Please wait... recording starts in {` 0.0${time}`}</p>}
      {isRecordingStarted && isStillRecording && <p className="fw400 success-color text-center font-size-15 text-capitalize">Recording started. {`0:0${secondaryTimer}`}</p>}
      <div>
        <Spacer height={10} />
        {video !== '' ? (
          <div className="d-flex align-center j-space-between">
            {!loading && <AppButton isActive={true} onClick={() => retakeVideo()} className="webcam-btn fw500" name="Retake Video" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={() => handleUpload()} name="Upload" />
          </div>
        ) : (
          <div></div>
        )}
        <Spacer height={10} />
      </div>{' '}
    </div>
  );
};

export const RolesWebcamVideoCapture = ({ setStage, onClose, info, livelyInfo }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);

  const webcamRef = useRef(null);
  const { time, startTimer, stopTimer } = useTimer(5);
  const { time: secondaryTimer, startTimer: secondaryStartTimer, stopTimer: secondaryStopTimer } = useTimer(6);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState('');
  const mediaRecorderRef = useRef(null);
  const [captured, setCaptured] = useState(false);
  const [displaying, setDisplaying] = useState(false);
  const recordedChunks = useRef([]);
  const stream = useRef(null);
  const [recording, setRecording] = useState(false);
  const queryClient = useQueryClient();
  const { uploadedImageUrl } = useSelector((state) => state?.roles);

  async function setUpCamera() {
    if (navigator) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: true,
      });
      webcamRef.current.srcObject = stream.current;
      setDisplaying(true);
      startTimer();
    }
  }

  const startRecording = () => {
    let options;
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
      options = { mimeType: 'video/webm; codecs=vp9' };
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      options = { mimeType: 'video/webm' };
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
    } else {
      openNotificationWithIcon('error', 'Error', 'Media Type cannot be played on this device');
    }
    if (displaying) {
      mediaRecorderRef.current = new MediaRecorder(stream.current, options);
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.onstop = doPreview;
      mediaRecorderRef.current.start(1000);
      setRecording(true);
    }
  };
  const doPreview = () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/mp4',
      });
      setVideoFile(URL.createObjectURL(blob));
    }
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      recordedChunks.current.push(data);
    }
  }, []);

  const stopRecording = () => {
    if (recording) {
      setVideo(recordedChunks.current);
      setVideoFile(recordedChunks.current);
      mediaRecorderRef.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
      setCaptured(true);
    }
  };

  useEffect(() => {
    setUpCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
      startRecording();
      secondaryStartTimer();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (secondaryTimer === 0) {
      secondaryStopTimer();
      stopRecording();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimer]);

  const handleUpload = async () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/webm',
      });
      const file = new File([blob], 'video.mp4', {
        type: blob.type,
      });
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      try {
        setLoading(true);
        const res = await uploadFilesToS3('roles_video', data);
        const { file_url } = res?.data?.data || '';
        // I used redux initially, however it disappears on reload, so we have to use LS now and persist it
        //dispatch(setImageUrl(file_url));
        saveToLS('image_url', file_url);

        if (res.status) {
          info.video_url = file_url;
          info.image_url = uploadedImageUrl;
          // console.log(info, ' this is what I am sending to the backend');
          try {
            // save the team member
            const res = await addTeamMember(key, info);
            // invalidate the existing team list
            queryClient.invalidateQueries({ queryKey: ['merchantTeamList'] });
            setLoading(false);
            openNotificationWithIcon('success', 'Success', 'Team Member Added Successfully');
            onClose();
          } catch (error) {
            openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
            setLoading(false);
            onClose();
          }
        }
      } catch (error) {
        setLoading(false);
        openNotificationWithIcon('error', 'Error', 'Error on uploading video');
        onClose();
      }
    }
  };

  const retakeVideo = () => {
    setVideo('');
    setVideoFile(null);
    recordedChunks.current = [];
    setCaptured(false);
    setStage(0);
  };

  const isCameraStarted = time >= 1;
  const isRecordingStarted = time <= 0;
  const isStillRecording = secondaryTimer >= 1;

  return (
    <div className="p-relative">
      {!captured && <Webcam ref={webcamRef} audio={false} imageSmoothing={true} id="cam_video" className="video-info-wrapper full-width d-flex align-center j-center" />}
      {videoFile && captured && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      {isCameraStarted && <p className="fw400 action-color text-center font-size-15 text-capitalize">Please wait... recording starts in {` 0.0${time}`}</p>}
      {isRecordingStarted && isStillRecording && <p className="fw400 success-color text-center font-size-15 text-capitalize">Recording started. {`0:0${secondaryTimer}`}</p>}
      <div>
        <Spacer height={10} />
        {video !== '' ? (
          <div className="d-flex align-center j-space-between">
            {!loading && <AppButton isActive={true} onClick={() => retakeVideo()} className="webcam-btn fw500" name="Retake Video" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={() => handleUpload()} name="Upload" />
          </div>
        ) : (
          <div></div>
        )}
        <Spacer height={10} />
      </div>{' '}
    </div>
  );
};

export const PinResetWebCam = ({ setStage, onClose, service, folderName, oldPassword, newPassword, closePasswordPopup, isPin, otp, email, livelyInfo, otherInfo }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);

  const webcamRef = useRef(null);
  const { time, startTimer, stopTimer } = useTimer(5);
  const { time: secondaryTimer, startTimer: secondaryStartTimer, stopTimer: secondaryStopTimer } = useTimer(6);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState('');
  const mediaRecorderRef = useRef(null);
  const [captured, setCaptured] = useState(false);
  const [displaying, setDisplaying] = useState(false);
  const recordedChunks = useRef([]);
  const stream = useRef(null);
  const [recording, setRecording] = useState(false);

  const history = useHistory();

  async function setUpCamera() {
    if (navigator) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: true,
      });
      webcamRef.current.srcObject = stream.current;
      setDisplaying(true);
      startTimer();
    }
  }

  const startRecording = () => {
    if (displaying) {
      mediaRecorderRef.current = new MediaRecorder(stream.current, {
        mimeType: 'video/webm',
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.onstop = doPreview;
      mediaRecorderRef.current.start(1000);
      setRecording(true);
    }
  };
  const doPreview = () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/mp4',
      });
      setVideoFile(URL.createObjectURL(blob));
    }
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      recordedChunks.current.push(data);
    }
  }, []);

  const stopRecording = () => {
    if (recording) {
      setVideo(recordedChunks.current);
      setVideoFile(recordedChunks.current);
      mediaRecorderRef.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
      setCaptured(true);
    }
  };

  useEffect(() => {
    setUpCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
      startRecording();
      secondaryStartTimer();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (secondaryTimer === 0) {
      secondaryStopTimer();
      stopRecording();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimer]);

  const closePopup = () => {
    dispatch(fetchUserMerchant(id));
    onClose();
  };

  const handleUpload = async () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/webm',
      });
      const file = new File([blob], 'video.mp4', {
        type: blob.type,
      });
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      const appToken = await createGoogleRecaptchaV3();
      console.log(otherInfo, ' other info');

      try {
        setLoading(true);
        const res = await uploadFilesToS3(folderName, data);
        const res_status = res?.data?.status;
        const res_file_url = res?.data?.data?.file_url;
        let info;
        if (res_status) {
          if (isPin === true && otp && otp.length > 1) {
            info = {
              otp: otp,
              pin: newPassword,
              video_url: res_file_url,
              liveliness_key: livelyInfo?.liveliness_key,
            };
          } else if (isPin === true) {
            info = {
              old_pin: oldPassword,
              new_pin: newPassword,
              video_url: res_file_url,
              liveliness_key: livelyInfo?.liveliness_key,
            };
          } else if (email && email.length > 1 && !isPin) {
            // this sets the info for forgot password
            info = {
              email: email,
              video_url: res_file_url,
              liveliness_key: livelyInfo?.liveliness_key,
            };
          } else {
            info = {
              old_password: oldPassword,
              password: newPassword,
              video_url: res_file_url,
              liveliness_key: livelyInfo?.liveliness_key,
            };
          }

          try {
            let res;

            if (email && email.length > 1 && !isPin) {
              res = await service(info, appToken?.token);
            } else {
              res = await service(key, info);
            }

            if (res?.data?.status) {
              if (email && email.length > 1 && !isPin) {
                openNotificationWithIcon('success', 'Success', res.data.message);
                setLoading(false);
                setVideo('');
                setVideoFile(null);
                recordedChunks.current = [];
                setCaptured(false);
                // closePopup();
                // closePasswordPopup(false);
                history.push('/verify-account');
              } else {
                openNotificationWithIcon('success', 'Success', res.data.message);
                setLoading(false);
                setVideo('');
                setVideoFile(null);
                recordedChunks.current = [];
                setCaptured(false);
                closePopup();
                closePasswordPopup(false);
              }
            }
          } catch (e) {
            console.log(e?.response);
            setLoading(false);
            openNotificationWithIcon('error', 'Error', e.response?.data?.message);
            closePopup();
            // closePasswordPopup(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  const retakeVideo = () => {
    setVideo('');
    setVideoFile(null);
    recordedChunks.current = [];
    setCaptured(false);
    setStage(0);
  };

  const isCameraStarted = time >= 1;
  const isRecordingStarted = time <= 0;
  const isStillRecording = secondaryTimer >= 1;

  return (
    <div className="p-relative">
      {!captured && <Webcam ref={webcamRef} audio={false} imageSmoothing={true} id="cam_video" className="video-info-wrapper full-width d-flex align-center j-center" />}
      {videoFile && captured && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      {isCameraStarted && <p className="fw400 action-color text-center font-size-15 text-capitalize">Please wait... recording starts in {` 0.0${time}`}</p>}
      {isRecordingStarted && isStillRecording && <p className="fw400 success-color text-center font-size-15 text-capitalize">Recording started. {`0:0${secondaryTimer}`}</p>}
      <div>
        <Spacer height={10} />
        {video !== '' ? (
          <div className="d-flex align-center j-space-between">
            {!loading && <AppButton isActive={true} onClick={() => retakeVideo()} className="webcam-btn fw500" name="Retake Video" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={() => handleUpload()} name="Upload" />
          </div>
        ) : (
          <div></div>
        )}
        <Spacer height={10} />
      </div>{' '}
    </div>
  );
};

export const PasswordResetWebCam = ({ setStage, onClose, service, folderName, oldPassword, newPassword, closePasswordPopup, isPin, otp, email, livelyInfo, otherInfo }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);

  const webcamRef = useRef(null);
  const { time, startTimer, stopTimer } = useTimer(5);
  const { time: secondaryTimer, startTimer: secondaryStartTimer, stopTimer: secondaryStopTimer } = useTimer(6);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState('');
  const mediaRecorderRef = useRef(null);
  const [captured, setCaptured] = useState(false);
  const [displaying, setDisplaying] = useState(false);
  const recordedChunks = useRef([]);
  const stream = useRef(null);
  const [recording, setRecording] = useState(false);

  const history = useHistory();

  async function setUpCamera() {
    if (navigator) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: true,
      });
      webcamRef.current.srcObject = stream.current;
      setDisplaying(true);
      startTimer();
    }
  }

  const startRecording = () => {
    if (displaying) {
      mediaRecorderRef.current = new MediaRecorder(stream.current, {
        mimeType: 'video/webm',
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.onstop = doPreview;
      mediaRecorderRef.current.start(1000);
      setRecording(true);
    }
  };
  const doPreview = () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/mp4',
      });
      setVideoFile(URL.createObjectURL(blob));
    }
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      recordedChunks.current.push(data);
    }
  }, []);

  const stopRecording = () => {
    if (recording) {
      setVideo(recordedChunks.current);
      setVideoFile(recordedChunks.current);
      mediaRecorderRef.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
      setCaptured(true);
    }
  };

  useEffect(() => {
    setUpCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
      startRecording();
      secondaryStartTimer();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (secondaryTimer === 0) {
      secondaryStopTimer();
      stopRecording();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimer]);

  const closePopup = () => {
    dispatch(fetchUserMerchant(id));
    onClose();
  };

  const handleUpload = async () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/webm',
      });
      const file = new File([blob], 'video.mp4', {
        type: blob.type,
      });
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      const appToken = await createGoogleRecaptchaV3();
      //console.log(otherInfo, ' other info');

      try {
        setLoading(true);
        const res = await uploadFilesToS3(folderName, data);
        const res_status = res?.data?.status;
        const res_file_url = res?.data?.data?.file_url;
        let info;
        if (res_status) {
          // this sets the info for forgot password
          info = {
            email: email,
            video_url: res_file_url,
            liveliness_key: otherInfo?.key,
          };

          try {
            let res;

            if (email && email.length > 1 && !isPin) {
              res = await service(info, appToken?.token);
            } else {
              res = await service(key, info);
            }

            if (res?.data?.status) {
              if (email && email.length > 1 && !isPin) {
                openNotificationWithIcon('success', 'Success', res.data.message);
                setLoading(false);
                setVideo('');
                setVideoFile(null);
                recordedChunks.current = [];
                setCaptured(false);
                // closePopup();
                // closePasswordPopup(false);
                history.push('/verify-account');
              } else {
                openNotificationWithIcon('success', 'Success', res.data.message);
                setLoading(false);
                setVideo('');
                setVideoFile(null);
                recordedChunks.current = [];
                setCaptured(false);
                closePopup();
                closePasswordPopup(false);
              }
            }
          } catch (e) {
            console.log(e?.response);
            setLoading(false);
            openNotificationWithIcon('error', 'Error', e.response?.data?.message);
            closePopup();
            // closePasswordPopup(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  const retakeVideo = () => {
    setVideo('');
    setVideoFile(null);
    recordedChunks.current = [];
    setCaptured(false);
    setStage(0);
  };

  const isCameraStarted = time >= 1;
  const isRecordingStarted = time <= 0;
  const isStillRecording = secondaryTimer >= 1;

  return (
    <div className="p-relative">
      {!captured && <Webcam ref={webcamRef} audio={false} imageSmoothing={true} id="cam_video" className="video-info-wrapper full-width d-flex align-center j-center" />}
      {videoFile && captured && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      {isCameraStarted && <p className="fw400 action-color text-center font-size-15 text-capitalize">Please wait... recording starts in {` 0.0${time}`}</p>}
      {isRecordingStarted && isStillRecording && <p className="fw400 success-color text-center font-size-15 text-capitalize">Recording started. {`0:0${secondaryTimer}`}</p>}
      <div>
        <Spacer height={10} />
        {video !== '' ? (
          <div className="d-flex align-center j-space-between">
            {!loading && <AppButton isActive={true} onClick={() => retakeVideo()} className="webcam-btn fw500" name="Retake Video" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={() => handleUpload()} name="Upload" />
          </div>
        ) : (
          <div></div>
        )}
        <Spacer height={10} />
      </div>{' '}
    </div>
  );
};

export const PinChangeWebCam = ({ setStage, onClose, service, folderName, oldPin, newPin, setOpenPinModal, isPassword, livelyInfo }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);

  // use the liveliness key from the endpoint, questions are no longer accepted by the BE
  // let liveliness_question = 'Please record a 5 seconds video where you say your name and your location to complete your KYC process.';
  let liveliness_question = livelyInfo?.liveliness_question;

  const webcamRef = useRef(null);
  const { time, startTimer, stopTimer } = useTimer(5);
  const { time: secondaryTimer, startTimer: secondaryStartTimer, stopTimer: secondaryStopTimer } = useTimer(6);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState('');
  const mediaRecorderRef = useRef(null);
  const [captured, setCaptured] = useState(false);
  const [displaying, setDisplaying] = useState(false);
  const recordedChunks = useRef([]);
  const stream = useRef(null);
  const [recording, setRecording] = useState(false);

  async function setUpCamera() {
    if (navigator) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: true,
      });
      webcamRef.current.srcObject = stream.current;
      setDisplaying(true);
      startTimer();
    }
  }

  const startRecording = () => {
    if (displaying) {
      mediaRecorderRef.current = new MediaRecorder(stream.current, {
        mimeType: 'video/webm',
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.onstop = doPreview;
      mediaRecorderRef.current.start(1000);
      setRecording(true);
    }
  };
  const doPreview = () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/mp4',
      });
      setVideoFile(URL.createObjectURL(blob));
    }
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      recordedChunks.current.push(data);
    }
  }, []);

  const stopRecording = () => {
    if (recording) {
      setVideo(recordedChunks.current);
      setVideoFile(recordedChunks.current);
      mediaRecorderRef.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
      setCaptured(true);
    }
  };

  useEffect(() => {
    setUpCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
      startRecording();
      secondaryStartTimer();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (secondaryTimer === 0) {
      secondaryStopTimer();
      stopRecording();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimer]);

  const closePopup = () => {
    dispatch(fetchUserMerchant(id));
    onClose();
  };

  const handleUpload = async () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/webm',
      });
      const file = new File([blob], 'video.mp4', {
        type: blob.type,
      });
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      // data.append('cloud_name', getCloudNameFromEnv());
      // data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
      // data.append('folder', folderName);
      // data.append('api_key', getCloudKeyFromEnv());

      try {
        setLoading(true);
        const res = await uploadFilesToS3(folderName, data);
        const res_status = res?.data?.status;
        const res_file_url = res?.data?.data?.file_url;

        let info;
        if (res_status) {
          if (isPassword) {
            info = {
              old_password: oldPin,
              password: newPin,
              video_url: res_file_url,
              liveliness_key: livelyInfo?.liveliness_key,
            };
          } else {
            info = {
              old_pin: oldPin,
              new_pin: newPin,
              video_url: res_file_url,
              liveliness_key: livelyInfo?.liveliness_key,
            };
          }

          try {
            let res;
            if (isPassword) {
              res = await service(info);
            } else {
              res = await service(key, info);
            }
            if (res?.data?.status) {
              openNotificationWithIcon('success', 'Success', res.data.message, 7);
              setLoading(false);
              setVideo('');
              setVideoFile(null);
              recordedChunks.current = [];
              setCaptured(false);
              closePopup();
              setOpenPinModal(false);
            }
          } catch (e) {
            console.log(e);
            setLoading(false);
            openNotificationWithIcon('error', 'Error', e.response?.data?.message);
            closePopup();
            // closePasswordPopup(false);
          }
        } else {
          setLoading(false);
          openNotificationWithIcon('error', 'Error', 'An error occurred, try again.');
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  const retakeVideo = () => {
    setVideo('');
    setVideoFile(null);
    recordedChunks.current = [];
    setCaptured(false);
    setStage(0);
  };

  const isCameraStarted = time >= 1;
  const isRecordingStarted = time <= 0;
  const isStillRecording = secondaryTimer >= 1;

  return (
    <div className="p-relative">
      {!captured && <Webcam ref={webcamRef} audio={false} imageSmoothing={true} id="cam_video" className="video-info-wrapper full-width d-flex align-center j-center" />}
      {videoFile && captured && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      {isCameraStarted && <p className="fw400 action-color text-center font-size-15 text-capitalize">Please wait... recording starts in {` 0.0${time}`}</p>}
      {isRecordingStarted && isStillRecording && <p className="fw400 success-color text-center font-size-15 text-capitalize">Recording started. {`0:0${secondaryTimer}`}</p>}
      <div>
        <Spacer height={10} />
        {video !== '' ? (
          <div className="d-flex align-center j-space-between">
            {!loading && <AppButton isActive={true} onClick={() => retakeVideo()} className="webcam-btn fw500" name="Retake Video" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={() => handleUpload()} name="Upload" />
          </div>
        ) : (
          <div></div>
        )}
        <Spacer height={10} />
      </div>{' '}
    </div>
  );
};

export const LimitResetWebCam = ({ setStage, onClose, folderName, setParentStage, setVideoUrl }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  let { liveliness_question } = useSelector((state) => state.merchant.merchantDetails);
  if (liveliness_question === undefined || liveliness_question === null) {
    liveliness_question = 'Please record a 5 seconds video where you say your name and your location to complete your KYC process.';
  }
  const webcamRef = useRef(null);
  const { time, startTimer, stopTimer } = useTimer(5);
  const { time: secondaryTimer, startTimer: secondaryStartTimer, stopTimer: secondaryStopTimer } = useTimer(6);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState('');
  const mediaRecorderRef = useRef(null);
  const [captured, setCaptured] = useState(false);
  const [displaying, setDisplaying] = useState(false);
  const recordedChunks = useRef([]);
  const stream = useRef(null);
  const [recording, setRecording] = useState(false);

  const history = useHistory();

  async function setUpCamera() {
    if (navigator) {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: true,
      });
      webcamRef.current.srcObject = stream.current;
      setDisplaying(true);
      startTimer();
    }
  }

  const startRecording = () => {
    if (displaying) {
      mediaRecorderRef.current = new MediaRecorder(stream.current, {
        mimeType: 'video/webm',
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.onstop = doPreview;
      mediaRecorderRef.current.start(1000);
      setRecording(true);
    }
  };
  const doPreview = () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/mp4',
      });
      setVideoFile(URL.createObjectURL(blob));
    }
  };

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      recordedChunks.current.push(data);
    }
  }, []);

  const stopRecording = () => {
    if (recording) {
      setVideo(recordedChunks.current);
      setVideoFile(recordedChunks.current);
      mediaRecorderRef.current.stop();
      stream.current.getTracks().forEach((track) => track.stop());
      setRecording(false);
      setCaptured(true);
    }
  };

  useEffect(() => {
    setUpCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
      startRecording();
      secondaryStartTimer();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (secondaryTimer === 0) {
      secondaryStopTimer();
      stopRecording();
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimer]);

  const closePopup = () => {
    dispatch(fetchUserMerchant(id));
    onClose();
  };

  const handleUpload = async () => {
    if (recordedChunks.current.length) {
      const blob = new Blob(recordedChunks.current, {
        type: 'video/webm',
      });
      const file = new File([blob], 'video.mp4', {
        type: blob.type,
      });
      const data = new FormData();
      if (file) {
        data.append('file', file);
      }
      // data.append('cloud_name', getCloudNameFromEnv());
      // data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
      // data.append('folder', folderName);
      // data.append('api_key', getCloudKeyFromEnv());

      try {
        setLoading(true);
        const res = await uploadFilesToS3(folderName, data);
        const res_status = res?.data?.status;
        const res_file_url = res?.data?.data?.file_url;

        if (res_status) {
          setVideoUrl(res_file_url);
          setLoading(false);
          setVideo('');
          setVideoFile(null);
          recordedChunks.current = [];
          setCaptured(false);
          setParentStage(3);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
        setParentStage(1);
      }
    }
  };

  const retakeVideo = () => {
    setVideo('');
    setVideoFile(null);
    recordedChunks.current = [];
    setCaptured(false);
    setStage(0);
  };

  const isCameraStarted = time >= 1;
  const isRecordingStarted = time <= 0;
  const isStillRecording = secondaryTimer >= 1;

  return (
    <div className="p-relative">
      {!captured && <Webcam ref={webcamRef} audio={false} imageSmoothing={true} id="cam_video" className="video-info-wrapper full-width d-flex align-center j-center" />}
      {videoFile && captured && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      {isCameraStarted && <p className="fw400 action-color text-center font-size-15 text-capitalize">Please wait... recording starts in {` 0.0${time}`}</p>}
      {isRecordingStarted && isStillRecording && <p className="fw400 success-color text-center font-size-15 text-capitalize">Recording started. {`0:0${secondaryTimer}`}</p>}
      <div>
        <Spacer height={10} />
        {video !== '' ? (
          <div className="d-flex align-center j-space-between">
            {!loading && <AppButton isActive={true} onClick={() => retakeVideo()} className="webcam-btn fw500" name="Retake Video" />}
            <Spacer width={15} />
            <AppButton isBusy={loading} isActive={true} className="webcam-btn fw500" onClick={() => handleUpload()} name="Upload" />
          </div>
        ) : (
          <div></div>
        )}
        <Spacer height={10} />
      </div>{' '}
    </div>
  );
};

export const RenderVideo = ({ videoFile, service, data, onClose }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const { uploadedImageUrl } = useSelector((state) => state?.roles);
  const video_url = getInLS('image_url');
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const getLiveKeyFromLS = () => {
    let key = getInLS('liveliness_key');
    if (key) {
      return key;
    }
    return '';
  };

  data.liveliness_key = getLiveKeyFromLS();

  const addMember = async (info) => {
    info.image_url = uploadedImageUrl;
    info.video_url = video_url;
    setLoading(true);
    try {
      const res = await service(key, info);
      queryClient.invalidateQueries({ queryKey: ['merchantTeamList'] });
      setLoading(false);
      openNotificationWithIcon('success', 'Success', 'Team Member Added Successfully');
      onClose();
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
      setLoading(false);
      onClose();
    }
  };

  return (
    <>
      {videoFile && <video controls className="video-info-wrapper full-width d-flex align-center j-center fade-in" src={videoFile}></video>}
      <Spacer height={20} />
      {videoFile && <AppButton isBusy={loading} isActive={true} className="webcam-btn-long fw500" onClick={() => addMember(data)} name="Add Team Member" />}
    </>
  );
};
