import { LoadingOutlined } from '@ant-design/icons';
import { faCaretDown, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import QRCode from 'qrcode.react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import back from '../../assets/images/back-arrow.svg';
import bankTransfer from '../../assets/images/bank-transfer.svg';
import cancel from '../../assets/images/cancel.svg';
import check from '../../assets/images/check.svg';
import clip from '../../assets/images/clip.svg';
import { ReactComponent as IdSVG } from '../../assets/images/idcard.svg';
import loans from '../../assets/images/loans.svg';
import network from '../../assets/images/no_network.svg';
import { ReactComponent as PassportSVG } from '../../assets/images/passport.svg';
import profile from '../../assets/images/profile-pic.png';
import qrcodeScan from '../../assets/images/qr-code-scan.svg';
import warning from '../../assets/images/warning.svg';
import { useOutsideRef } from '../../hooks/useOutsideClick';
import { useFormFile } from '../../hooks/use_form_file';
import { useInput } from '../../hooks/use_input';
import useOverflow from '../../hooks/use_overflow';
import usePopup from '../../hooks/use_popup';
import useSyncState from '../../hooks/use_sync_state';
import useVerifiedUser from '../../hooks/use_user_verified';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { AlignRight, GenericTabsPopup } from '../../pages/merchant/transfer';
import { PinInputGrid } from '../../pages/merchant/withdraw';
import { closeQR, fetchUserMerchant } from '../../redux/merchant/merchant_actions';
import { closeCashpointQR, closeSessionModal } from '../../redux/user/user_actions';
import {
  ammbanUpload,
  cacUpload,
  confirmBVN_NIN,
  getCashierMerchantDetails,
  getLivelinessKey,
  internationalPassportUpload,
  otherUpload,
  proofOfAddressUpload,
  uploadFilesToS3,
  uploadID,
  verifyBVN_NIN,
} from '../../services/apiSevices';
import colors from '../../utils/color/colors';
import { formatNumber } from '../../utils/formatNumber';
import { externalRedirect, getBase64String, getCloudKeyFromEnv, getCloudNameFromEnv, getCloudUrlFromEnv, isNotAWebUser } from '../../utils/helper';
import { saveToLS } from '../../utils/local';
import AppButton from '../app_button/app_button';
import AppDivider from '../app_divider/app_divider';
import { GradientBackground } from '../contact_content/contact_content';
import { AppInput, FormInput } from '../form_input/form_input';
import { DocumentTypeChooser } from '../image_upload/image_upload';
import { Spacer } from '../layout/layout';
import { BvnOrNinInput, DocumentTextInfo, DottedDocumentUpload, TwoRowText } from '../merchant_widgets/merchant_widgets';
import { TimeCountdown } from '../time_countdown/time_countdown';
import { LimitResetWebCam, PasswordResetWebCam, PinChangeWebCam, PinResetWebCam, RolesWebcamVideoCapture, WebcamCapture, WebcamVideoCapture } from '../webcam/webcam';
import './popup.css';

const Popup = forwardRef(({ children, title, onClose, textRight, width, noPadding, flag, className, height, others, maxHeight }, ref) => {
  useOverflow();
  return (
    <div className="p-fixed full-height full-width popup fade-in">
      <div className="d-flex j-center">
        <div
          ref={ref}
          className={`popup-content p-relative ${className || ''}`}
          style={{
            width: width ?? '35%',
            padding: !noPadding ? '30px' : '0px',
            height: height,
            maxHeight: maxHeight,
          }}
        >
          <div className="title-area d-flex align-center">
            <p className={`flex-one ${textRight ? '' : 'text-center'} text-color font-size-20 fw700 text-capitalize`}>{title}</p>
            {!flag && (
              <button type="button" onClick={onClose} className="unstyle-button c-pointer rounded-widget text-center text-white container-size-small d-flex align-center j-center">
                <img src={cancel} alt="close action" />
              </button>
            )}
          </div>
          {others ? <div className="d-flex flex-column j-space-between full-height">{children}</div> : children}
        </div>
      </div>
    </div>
  );
});

export default Popup;

export const SessionExpirationPopup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onClick = () => {
    closeSessionModal(dispatch);
    sessionStorage.clear();
    localStorage.clear();
    history.push('/login');
  };
  return (
    <Popup width="35%" flag>
      <div style={{ padding: '20px 40px' }}>
        <div className="d-flex flex-vertical align-center">
          <div className="d-flex align-center">
            <img src={warning} alt="" />
            <Spacer width={10} />
            <p className="fw500 no-margin font-size-18">Session Has Expired.</p>
          </div>
          <Spacer height={15} />
          <span className="fw400 font-size-17 text-color text-center">Due to inactivity or another login elsewhere, your session has expired. Simply log in to access your account.</span>
          <Spacer height={15} />
          <AppButton isActive={true} onClick={onClick} className="done-editing font-size-17 fw400 full-width primary-color" name="Log In" />
          <Spacer height={15} />
        </div>
      </div>
    </Popup>
  );
};

export const SetupBankPopupAlert = ({ onClose }) => {
  const history = useHistory();
  const onClick = () => {
    onClose();
    history.push({
      pathname: '/admin/merchant/settings',
      state: { tab: 'Payout Accounts' },
    });
  };
  return (
    <SmallAlertPopup
      btnName="Go To Settings"
      onClick={onClick}
      onClose={onClose}
      text="Please click on the Settings menu and setup your payout bank account in order to withdraw your customer payments."
    />
  );
};

const SmallAlertPopup = ({ onClose, onClick, text, btnName }) => {
  return (
    <Popup width="35%" onClose={onClose}>
      <div style={{ padding: '20px 40px' }}>
        <div className="d-flex flex-vertical align-center">
          <span className="fw400 font-size-17 text-color text-center">{text}</span>
          <Spacer height={15} />
          <AppButton isActive={true} onClick={onClick} className="done-editing font-size-17 fw400 full-width primary-color" name={btnName} />
          <Spacer height={15} />
        </div>
      </div>
    </Popup>
  );
};

export const LogoutPopup = ({ setShow, onClose }) => {
  const history = useHistory();
  const onClick = () => {
    const tour = localStorage.getItem('tour');
    sessionStorage.clear();
    localStorage.clear();
    if (tour) {
      localStorage.setItem('tour', tour);
    }
    history.push('/login');
    setShow(false);
  };
  return <SmallAlertPopup btnName="Logout" onClick={onClick} onClose={onClose} text="Are you sure you want to Logout?" />;
};

export const NoNetworkPopup = () => {
  const onClick = () => {
    window.location.reload();
  };
  return (
    <Popup width="45%" flag>
      <div style={{ padding: '20px 40px' }}>
        <div className="d-flex flex-vertical align-center">
          <img src={network} alt="" />
          <Spacer height={15} />
          <p className="fw400 font-size-20 text-color no-margin">No Internet Connection</p>
          <Spacer height={10} />
          <span className="fw400 font-size-17 text-color text-center">Check your internet connection settings and try again.</span>
          <Spacer height={15} />
          <AppButton isActive={true} onClick={onClick} className="done-editing font-size-17 fw400 full-width primary-color" name="Reload Page" />
          <Spacer height={15} />
        </div>
      </div>
    </Popup>
  );
};

export const AccountInfoPopup = () => {
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  const { classification } = useSelector((state) => state?.merchant?.merchantDetails);
  const currentMerchant = useSelector((state) => state.user.currentMerchant);
  const cashpoints = useSelector((state) => state.merchant.cashpoints);
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();
  const dispatch = useDispatch();
  const { userIsWebUser } = isNotAWebUser(classification);

  const onClose = () => {
    closeQR(dispatch);
  };

  const qrCodeValue = useMemo(() => {
    if (cashpoints && cashpoints.length > 0) {
      return cashpoints[0].qrcode;
    } else {
      return '';
    }
  }, [cashpoints]);
  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  const allKYCFlag = hasBeenVerified || isVerified;
  const verificationFlag = userIsWebUser || hasBeenVerified || isVerified;

  let verifiedToUse;
  if (userIsWebUser) {
    if (allKYCFlag) {
      verifiedToUse = true;
    }
  } else {
    verifiedToUse = false;
  }

  return (
    <Popup width="30%" onClose={onClose}>
      <div className="d-flex flex-vertical align-center">
        <div className="info-overlay" />
        <div className="d-flex flex-vertical align-center">
          <img src={profile} alt="" />
          <Spacer height={5} />
          <p className="fw500 no-margin font-size-17 text-center">{merchantDetails.contactfirstname + ' ' + merchantDetails.contactlastname}</p>
          <span className="fw400 font-size-15 text-center opacity-5">{merchantDetails?.businessemail && merchantDetails.businessemail}</span>
        </div>
        <Spacer height={40} />
        <div className="d-flex flex-vertical align-center">
          <span className="font-size-15 text-center action-color">Account Details</span>
          <Spacer height={10} />
          {userIsWebUser && (
            <CopyToClipboard text={currentMerchant.nuban && currentMerchant.nuban} onCopy={() => openNotificationWithIcon('success', 'Success', 'Account Number Copied')}>
              {allKYCFlag ? (
                <div className="d-flex align-center account-clip c-pointer">
                  <p className="fw700 no-margin text-center font-size-20">{currentMerchant.nuban && currentMerchant.nuban}</p>
                  <Spacer width={15} />
                  <img src={clip} alt="" />
                </div>
              ) : (
                <p className="fw500 no-margin text-center font-size-16 action-color ">Verify your account to view account details</p>
              )}
            </CopyToClipboard>
          )}
          <Spacer height={8} />
          <span className="font-size-17 text-center fw700">{merchantDetails.businessname && merchantDetails.businessname}</span>
          <Spacer height={5} />
          {userIsWebUser && allKYCFlag && (
            <span className="font-size-15 fw500 text-center">
              V Bank<span style={{ marginLeft: '5px' }}></span> (VFD Microfinance Bank)
            </span>
          )}
        </div>
        <Spacer height={20} />
        <div className="full-width" style={{ padding: '0px 10%' }}>
          <Divider />
        </div>
        {allKYCFlag && <QRCode value={qrCodeValue} />}
        <Spacer height={15} />
        {verificationFlag && (
          <span className="font-size-13 text-center fw400">
            Scan the QR code to copy the above <br />
            payment details
          </span>
        )}
        <Spacer height={10} />
        {verifiedToUse && (
          <div style={{ padding: '0px 10%' }} className="d-flex align-center j-center full-width">
            <Divider style={{ minWidth: '35%' }} />
            <span className="fw400 text-center full-width font-size-15" style={{ padding: '0 5px' }}>
              or dial
            </span>
            <Divider style={{ minWidth: '35%' }} />
          </div>
        )}
        {verifiedToUse && <USSDTextWrapper accountNumber={currentMerchant.nuban && currentMerchant.nuban} />} <Spacer height={10} />
      </div>
    </Popup>
  );
};

export const USSDTextWrapper = ({ accountNumber }) => {
  const _text = `*BankUSSDCode*000*5037+${accountNumber}+Amount#`;
  return (
    <div className="d-flex align-baseline full-width" style={{ columnGap: '6px', borderRadius: '10px', padding: '10px', background: 'rgba(255, 28, 38, 0.05)' }}>
      <p style={{ color: 'red', flex: '.87', overflowWrap: 'anywhere' }} className="font-size-14 text-center fw500">
        {_text}
      </p>
      <CopyToClipboard text={_text} onCopy={() => openNotificationWithIcon('success', 'Success', 'USSD Code Copied')}>
        <img className="c-pointer" src={clip} alt="" />
      </CopyToClipboard>
    </div>
  );
};

const generatePaymentData = [
  {
    id: 1,
    icon: bankTransfer,
    title: 'Bank Transfer',
    description: 'Make payment via Bank Transfer',
    selected: true,
  },
  {
    id: 2,
    icon: qrcodeScan,
    title: 'Scan QR Code',
    description: 'Scan QR Code to make Payment',
    selected: false,
  },
];

export const GeneratePaymentPopup = ({ onClose }) => {
  const dropdownRef = useRef(null);
  const {
    details: { nuban, qrcode },
  } = useSelector((state) => state.cashierInformation);
  const [generatePayItem, setGeneratePayItem] = useState(generatePaymentData);
  const { onpaymentShow, onpaymentClose, paymentShow } = usePopup('payment');
  const { reset: resetAmount, ...amountProps } = useInput('', 'text', null, null, true);
  const { reset: resetDescription, ...descProps } = useInput('');
  const selectedItem = generatePayItem.find((x) => x.selected);
  const onClick = (item) => setGeneratePayItem(generatePayItem.map((x) => (x.id === item.id ? { ...item, selected: true } : { ...x, selected: false })));
  useOutsideRef(dropdownRef, onpaymentClose);

  const [position, setPosition] = useState(0);

  const onGenerateClick = () => {
    if (amountProps.value && selectedItem?.title) {
      setPosition(1);
    }
  };

  const resetForm = () => {
    resetAmount();
    resetDescription();
  };

  const renderPopupOne = () => (
    <Popup
      width="40em"
      onClose={() => {
        onClose();
        setPosition(0);
      }}
      title="Generate Payment"
      textRight
    >
      <div className="d-flex flex-vertical align-center">
        <Spacer height={20} />
        <div className="full-width p-relative">
          <FormInput
            placeholder="Select Payment Method"
            value={selectedItem?.title || ''}
            onChange={() => {}}
            onClick={paymentShow ? onpaymentClose : onpaymentShow}
            inputClass="c-pointer"
            className="mb-10 fw400 font-size-17 important-full-width"
            readOnly
            icons={<FontAwesomeIcon icon={faCaretDown} />}
          />
          {paymentShow && (
            <div ref={dropdownRef} className="p-absolute z-index2 primary-bg-color pay-button-container">
              {generatePayItem.map((item) => (
                <GeneratePaymentPopupItem key={item.id} item={item} onClick={onClick} />
              ))}
            </div>
          )}
        </div>
        <FormInput {...amountProps} placeholder="Amount" className="mtb-10 font-size-17 fw400 important-full-width" />
        <textarea {...descProps} placeholder="Transaction Description" className="message-box mt-10 fw400 font-size-17 full-width"></textarea>
        <Spacer height={20} />
        <AlignRight>
          <AppButton isActive onClick={onGenerateClick} className="font-size-16 b-tn-padding action-color-bg fw500 primary-color" name="GENERATE PAYMENT" />
        </AlignRight>
      </div>
    </Popup>
  );

  const renderPopupTwo = () => (
    <Popup onClose={onClose} width="40em" title="Payment Details" textRight>
      <Spacer height={30} />
      <AppDivider height="1px" color="rgba(32, 14, 50)" />
      <div className="d-flex time_countdown full-width" style={{ justifyContent: 'flex-end' }}>
        <TimeCountdown onStop={() => setPosition(2)} />
      </div>
      <Spacer height={15} />
      <div className="d-flex j-space-between">
        <div className="full-width">
          <div className="two-row-text">
            <p className="text-capitalize font-size-17 faded-text-color fw300">Account Number</p>
            <Spacer height={5} />
            <p className="text-color font-size-20 half-width fw700">{nuban}</p>
          </div>
          <Divider />
          <TwoRowText title="Total Order" description={`₦${formatNumber(amountProps.value)}`} />
          <Spacer height={20} />
          {descProps.value && <TwoRowText title="Description" description={descProps.value} />}
        </div>
        <Divider type="vertical" style={{ height: '18em' }} />
        <div className="d-flex flex-vertical align-center">
          <QRCode value={qrcode ? qrcode : ''} />
          <Spacer height={10} />
          <p className="font-size-11 fw400 text-center text-color opacity-5">Scan the QR Code to copy the above payment details</p>
        </div>
      </div>
      <Divider />
      <div className="d-grid" style={{ gridTemplateColumns: '2fr 1fr', columnGap: '10px' }}>
        <AppButton
          isActive={true}
          onClick={() => {
            setPosition(0);
            resetForm();
          }}
          className="font-size-16 b-tn-padding action-color-bg fw500 primary-color"
          name="NEW TRANSACTION"
        />
        <button
          onClick={() => setPosition(2)}
          className="font-size-16 b-tn-padding fw500 text-color c-pointer opacity-5"
          style={{
            outline: 'none',
            background: 'transparent',
            border: '1px solid rgba(32, 14, 50, 0.3)',
            borderRadius: '5px',
          }}
        >
          ABORT
        </button>
      </div>
    </Popup>
  );

  const renderPopupThree = () => {
    return (
      <Popup width="40em" onClose={onClose}>
        <div className="d-flex flex-vertical align-center">
          <span className="fw400 font-size-17 text-color text-center">
            Do you want to Abort
            <br /> this transaction?
          </span>
          <Spacer height={15} />
          <AppButton
            isActive={true}
            onClick={() => {
              onClose();
              setPosition(0);
            }}
            className="done-editing font-size-15 fw400 half-width primary-color"
            name="Abort Transaction"
          />
          <Spacer height={15} />
          <span className="unstyle-button opacity-5 c-pointer fw400 font-size-15" onClick={() => setPosition(1)}>
            Cancel
          </span>
        </div>
      </Popup>
    );
  };

  const goPosition = () => {
    switch (position) {
      case 0:
        return renderPopupOne();
      case 1:
        return renderPopupTwo();
      case 2:
        return renderPopupThree();
      default:
        return;
    }
  };

  return goPosition();
};

const GeneratePaymentPopupItem = ({ item, onClick }) => {
  const { textColor } = colors();
  return (
    <>
      <button onClick={() => onClick({ ...item, selected: item.selected })} className="text-color c-pointer pay-button unstyle-button d-flex align-center">
        <img src={item?.icon} alt="" />
        <Spacer width={20} />
        <div className="d-flex flex-vertical align-start">
          <span className="fw400">{item?.title}</span>
          <span style={{ color: textColor.withOpacity(0.5) }} className="fw400">
            {item?.description}
          </span>
        </div>
        <Spacer width={50} />
        {item?.selected && <img src={check} alt="" />}
      </button>
      {item.id === 1 && <Divider />}
    </>
  );
};

export const CashpointAccountInfoPopup = () => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantAccountNumber, setMerchantAccountNumber] = useState('');
  const {
    user: { cashpointid },
  } = useSelector((state) => state.user);
  const {
    details: { contactfirstname, contactlastname, phone, qrcode, nuban, profile_picture },
  } = useSelector((state) => state.cashierInformation);
  const dispatch = useDispatch();

  const onClose = () => {
    closeCashpointQR(dispatch);
  };

  const data = {
    cashpoint: cashpointid,
  };

  useEffect(() => {
    const fetchCashpointMerchant = async () => {
      try {
        const res = await getCashierMerchantDetails(data);
        setMerchantName(res?.data?.name);
        setMerchantAccountNumber(res?.data?.accountnumber);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    };
    fetchCashpointMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup width="30%" onClose={onClose}>
      <div className="d-flex flex-vertical align-center">
        <div className="info-overlay" />
        <div className="d-flex flex-vertical align-center">
          <img src={profile_picture ? profile_picture : profile} className={`${profile_picture ? 'profile-picture' : ''}`} alt="" />
          <Spacer height={5} />
          <p className="fw500 no-margin font-size-17 text-center">{contactfirstname + ' ' + contactlastname}</p>
          <span className="fw400 font-size-15 text-center opacity-5">{phone && phone}</span>
        </div>
        <Spacer height={20} />
        <div className="d-flex flex-vertical align-center">
          <span className="font-size-15 text-center action-color">Account Details444</span>
          <Spacer height={10} />
          <CopyToClipboard text={merchantAccountNumber && merchantAccountNumber} onCopy={() => openNotificationWithIcon('success', 'Success', 'Account Number Copied')}>
            <div className="d-flex align-center account-clip c-pointer">
              <p className="fw700 no-margin text-center font-size-20">{merchantAccountNumber && merchantAccountNumber}</p>
              <Spacer width={15} />
              <img src={clip} alt="" />
            </div>
          </CopyToClipboard>
          <Spacer height={8} />
          <span className="font-size-18 fw500 text-center">{merchantName}</span>
          <span className="font-size-15 text-center fw400">V Bank (VFD Microfinance Bank) </span>
        </div>
        <Spacer height={10} />
        <div className="full-width" style={{ padding: '0px 10%' }}>
          <Divider />
        </div>
        <span className="font-size-13 text-center fw400" style={{ padding: '0 5px' }}>
          or scan this QR code with your VBank app to make a super quick transfer
        </span>
        <Spacer height={10} />
        <QRCode value={qrcode ? qrcode : ''} />
        <Spacer height={15} />
        <div style={{ padding: '0px 10%' }} className="d-flex align-center j-center full-width">
          <Divider style={{ minWidth: '35%' }} />
          <span className="fw400 text-center full-width font-size-15" style={{ padding: '0 5px' }}>
            or dial
          </span>
          <Divider style={{ minWidth: '35%' }} />
        </div>
        <USSDTextWrapper accountNumber={merchantAccountNumber && merchantAccountNumber} />
        <Spacer height={10} />
      </div>
    </Popup>
  );
};

export const OTPPopup = ({ onClose, dataVal, isLoading, resendClick }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);

  const onClick = async () => {
    try {
      if (pin.length === 5) {
        setLoading(true);
        const data = {
          bvn_nin: dataVal,
          otp: pin,
          type: 'bvn',
        };
        const res = await confirmBVN_NIN(key, data);
        if (res.data.status) {
          dispatch(fetchUserMerchant(id));
          openNotificationWithIcon('success', 'Success', res.data?.message);
          onClose();
        }
        setLoading(false);
      } else {
        return;
      }
    } catch (error) {
      // console.log({ error })
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };
  return (
    <Popup width="38%" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 font-size-17 text-center text-color no-margin">Enter OTP sent</p>
      <Spacer height={20} />
      <div className="full-width d-flex align-center j-center">
        <PinInputGrid fields={5} setValue={setPin} />
      </div>
      <Spacer height={20} />
      <div className="full-width d-flex align-center j-center">
        <AppButton isActive={true} isBusy={loading} onClick={onClick} className="c-pointer primary-color pin-con" name="Continue" />
      </div>
      <Spacer height={20} />
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <p onClick={resendClick} className="c-pointer text-center action-color fw400 font-size-15">
          Didn't get the OTP? Click to resend
        </p>
      )}
    </Popup>
  );
};

export const VideoInfoPopup = ({ onClose }) => {
  const [stage, setStage] = useState(0);

  const { isLoading, isError, data: queryData } = useQuery({ queryKey: ['getLiveVideoKey'], queryFn: () => getLivelinessKey(), refetchInterval: false, refetchOnWindowFocus: false });

  const info = queryData?.data?.data;
  let utterance = new SpeechSynthesisUtterance(info?.liveliness_question);
  useEffect(() => {
    !isLoading && speechSynthesis.speak(utterance);
    speechSynthesis.speak(utterance);

    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info?.liveliness_question]);

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <WebcamVideoCapture setStage={setStage} onClose={onClose} liveliness_data={info} />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const RolesVideoInfoPopup = ({ onClose, data }) => {
  const [stage, setStage] = useState(0);

  const { isLoading, isError, data: queryData } = useQuery({ queryKey: ['getLiveVideoKey'], queryFn: () => getLivelinessKey(), refetchInterval: false, refetchOnWindowFocus: false });
  const info = queryData?.data?.data;
  let utterance = new SpeechSynthesisUtterance(info?.liveliness_question);

  // get the current time, add 10 minutes and save to LS
  const now = dayjs();
  const timeVideoExps = now.add(10, 'minute');
  const formattedExpDate = timeVideoExps.format('DD MMM YYYY. hh:mm:ss a');
  saveToLS('rolesVideoExpTime', formattedExpDate);
  saveToLS('liveliness_key', info?.liveliness_key);

  useEffect(() => {
    !isLoading && speechSynthesis.speak(utterance);
    speechSynthesis.speak(utterance);

    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info?.liveliness_question]);

  data.liveliness_key = info?.liveliness_key;

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <RolesWebcamVideoCapture onClose={onClose} info={data} setStage={setStage} />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const LimitVideoPopup = ({ onClose, setParentStage, setVideoUrl }) => {
  const [stage, setStage] = useState(0);
  const { liveliness_question } = useSelector((state) => state.merchant.merchantDetails);
  let utterance = new SpeechSynthesisUtterance(liveliness_question);

  useEffect(() => {
    speechSynthesis.speak(utterance);

    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{liveliness_question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <LimitResetWebCam setStage={setStage} onClose={onClose} folderName="Reset Limit Video" setParentStage={setParentStage} setVideoUrl={setVideoUrl} />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{liveliness_question}</p>
          </div>
          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const ResetVideoPopup = ({ onClose, service, folderName, oldPassword, newPassword, closePasswordPopup, otp, isPin, email }) => {
  const [stage, setStage] = useState(0);
  const [livelyInfo, setLivelyInfo] = useState({});

  const { isLoading, isError, data } = useQuery({ queryKey: ['getLiveVideoKey'], queryFn: async () => await getLivelinessKey(), refetchInterval: false, refetchOnWindowFocus: false });
  const info = data?.data?.data;

  let utterance = new SpeechSynthesisUtterance(info?.liveliness_question);

  useEffect(() => {
    speechSynthesis.speak(utterance);
    setLivelyInfo(info);
    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info?.liveliness_question]);

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <PinResetWebCam
              setStage={setStage}
              onClose={onClose}
              service={service}
              oldPassword={oldPassword}
              folderName={folderName}
              newPassword={newPassword}
              closePasswordPopup={closePasswordPopup}
              otp={otp}
              isPin={isPin}
              email={email}
              livelyInfo={livelyInfo}
            />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const ResetPasswordVideoPopup = ({ onClose, service, folderName, closePasswordPopup, email, otherInfo }) => {
  const [stage, setStage] = useState(0);
  const [livelyInfo, setLivelyInfo] = useState({});

  let utterance = new SpeechSynthesisUtterance(otherInfo?.question);

  useEffect(() => {
    speechSynthesis.speak(utterance);
    setLivelyInfo(otherInfo);
    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherInfo?.question]);

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{otherInfo?.question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <PasswordResetWebCam
              setStage={setStage}
              onClose={onClose}
              service={service}
              folderName={folderName}
              closePasswordPopup={closePasswordPopup}
              email={email}
              livelyInfo={livelyInfo}
              otherInfo={otherInfo}
            />
          </div>
          <Spacer height={10} />

          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{otherInfo?.question}</p>
          </div>

          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const PinResetVideoPopup = ({ onClose, service, folderName, oldPassword, newPassword, closePasswordPopup, otp, isPin, email }) => {
  const [stage, setStage] = useState(0);
  let liveliness_question = 'Please record a 10 seconds video where you say your name and your location to complete your KYC process.';

  let utterance = new SpeechSynthesisUtterance(liveliness_question);

  useEffect(() => {
    speechSynthesis.speak(utterance);

    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{liveliness_question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <PinResetWebCam
              setStage={setStage}
              onClose={onClose}
              service={service}
              oldPassword={oldPassword}
              folderName={folderName}
              newPassword={newPassword}
              closePasswordPopup={closePasswordPopup}
              otp={otp}
              isPin={isPin}
              email={email}
            />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{liveliness_question}</p>
          </div>
          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const PinChangeVideoPopup = ({ onClose, service, folderName, oldPin, newPin, setOpenPinModal, isPassword }) => {
  const [stage, setStage] = useState(0);
  const [livelyInfo, setLivelyInfo] = useState({});
  // let { liveliness_question } = useSelector((state) => state.merchant.merchantDetails);
  const { isLoading, isError, data } = useQuery({ queryKey: ['getLiveVideoKey'], queryFn: () => getLivelinessKey(), refetchInterval: false, refetchOnWindowFocus: false });
  const info = data?.data?.data;

  //let liveliness_question = 'Please record a 10 seconds video where you say your name and your location to complete your KYC process,';
  let utterance = !isLoading && new SpeechSynthesisUtterance(info?.liveliness_question);

  useEffect(() => {
    !isLoading && speechSynthesis.speak(utterance);
    setLivelyInfo(info);
    return () => speechSynthesis.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info?.liveliness_question]);

  return (
    <Popup width="40em" onClose={onClose}>
      <Spacer height={20} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Record A Liveliness Video</p>
      <Spacer height={10} />
      {stage === 0 && (
        <div>
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <FontAwesomeIcon icon={faVideo} size="7x" />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
          <AppButton onClick={() => setStage(1)} isActive={true} isBusy={false} className="primary-color fw500 upload-button action-color-bg full-width" name="Record Video" />
          <Spacer height={10} />
        </div>
      )}
      {stage === 1 && (
        <div className="fade-in">
          <div className="video-info-wrapper full-width d-flex align-center j-center video-padding">
            <PinChangeWebCam
              setStage={setStage}
              folderName={folderName}
              service={service}
              oldPin={oldPin}
              newPin={newPin}
              onClose={onClose}
              setOpenPinModal={setOpenPinModal}
              isPassword={isPassword}
              livelyInfo={livelyInfo}
            />
          </div>
          <Spacer height={10} />
          <div className="text-center ml-40 mr-40">
            <p className="fw500 text-color font-size-13">{info?.liveliness_question}</p>
          </div>
          <Spacer height={20} />
        </div>
      )}
    </Popup>
  );
};

export const ChoosePersonalDocumentPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const [stage, setStage] = useState(1);
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const frontProps = useFormFile(true);
  const backProps = useFormFile(true);
  const info = useSyncState({
    front_image_url: '',
    back_image_url: '',
    selfie_image_url: '',
    selfie_imagebase64: '',
    front_imagebase64: '',
    back_imagebase64: '',
  });

  const onPopupClose = () => {
    dispatch(fetchUserMerchant(id));
    setStage(1);
    onClose();
  };

  const onUploadClick = async (file) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }

    try {
      setLoading(true);

      const res = await uploadFilesToS3('PersonalIdentificationDocument', data);
      const file_url = res?.data?.data?.file_url;
      if (res.status && stage === 1) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Upload Next Document');

        info.set({
          ...info.get(),
          front_image_url: file_url,
          front_imagebase64: getBase64String(frontProps.src),
        });

        setStage(2);
      }
      if (file_url && stage === 2) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Upload Next Document');
        info.set({
          ...info.get(),
          back_image_url: file_url,
          back_imagebase64: getBase64String(backProps.src),
        });
        setStage(3);
      }
      if (file_url && stage === 3) {
        info.set({
          ...info.get(),
          selfie_image_url: file_url,
          selfie_imagebase64: getBase64String(image),
        });

        const res = await uploadID(info.get(), key);
        if (res.data.status) {
          openNotificationWithIcon('success', 'Success', 'Documents Uploaded Successfully');
          setLoading(false);
          onPopupClose();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      console.error(error.response);
    }
  };

  const renderFrontUpload = (props, type) => {
    //console.log(props);
    return (
      <div className="d-flex align-center flex-vertical justify-center">
        <DottedText props={props} text={`Upload the ${type} of your Identity Card`} />
        <Spacer height={10} />
        {props?.src && <AppButton onClick={() => onUploadClick(props.file)} isActive={true} isBusy={loading} className="primary-color fw500 upload-button full-width action-color-bg" name="Upload" />}
        <Spacer height={10} />
      </div>
    );
  };

  const uploadWrapper = () => (
    <div>
      {show && stage === 1 && renderFrontUpload(frontProps, 'Front')}
      {show && stage === 2 && renderFrontUpload(backProps, 'Back')}
      {show && stage === 3 && (
        <div>
          <p className="fw400 faded-text-color text-center font-size-15 text-capitalize">
            Take a Clear <strong>Selfie</strong> of yourself holding your ID Card
          </p>
          <Spacer height={15} />
          <WebcamCapture loading={loading} onSubmitClick={() => onUploadClick(imageFile)} image={image} setImageFile={setImageFile} setImage={setImage} />
        </div>
      )}
    </div>
  );

  return (
    <Popup width="40%" className="max-width-600" onClose={onPopupClose}>
      <Spacer height={10} />
      <DocumentTextInfo text="Upload your unexpired Nigerian Government-issued ID card" />
      <Spacer height={30} />
      <p className="fw400 faded-text-color font-size-15 text-capitalize">Choose your document type</p>
      <Spacer height={20} />
      <DocumentTypeChooser svg={<IdSVG />} text="Driver's License, Voter's Card, NIN card, or National ID Card" onClick={() => setShow(!show)} />
      <Spacer height={10} />
      {show && uploadWrapper()}
      <DocumentTypeChooser svg={<PassportSVG />} text="International Passport" onClick={() => setShow2(!show2)} />
      {show2 && <InternationalPassportUpload onClose={onClose} show2={show2} />}
    </Popup>
  );
};

export const InternationalPassportUpload = ({ onClose }) => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const dispatch = useDispatch();
  const frontProps = useFormFile(true);
  const backProps = useFormFile(true);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState('');
  const [stage, setStage] = useState(1);
  const info = useSyncState({
    front_image_url: '',
    back_image_url: '',
    selfie_image_url: '',
    selfie_imagebase64: '',
    front_imagebase64: '',
    back_imagebase64: '',
  });

  const onPopupClose = () => {
    dispatch(fetchUserMerchant(id));
    onClose();
  };

  const onUploadClick = async (file) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }
    data.append('cloud_name', getCloudNameFromEnv());
    data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
    data.append('folder', 'PersonalIdentificationDocument');
    data.append('api_key', getCloudKeyFromEnv());

    try {
      setLoading(true);
      const res = await uploadFilesToS3('PersonalIdentificationDocument', data);
      const file_url = res?.data?.data?.file_url;
      if (file_url && stage === 1) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Upload Next Document');

        info.set({
          ...info.get(),
          front_image_url: file_url,
          front_imagebase64: getBase64String(frontProps.src),
        });
        setStage(2);
      }
      if (file_url && stage === 2) {
        setLoading(false);
        openNotificationWithIcon('success', 'Success', 'Upload Next Document');
        info.set({
          ...info.get(),
          back_image_url: file_url,
          back_imagebase64: getBase64String(backProps.src),
        });
        setStage(3);
      }
      if (file_url && stage === 3) {
        info.set({
          ...info.get(),
          selfie_image_url: file_url,
          selfie_imagebase64: getBase64String(image),
        });

        const res = await internationalPassportUpload(info.get(), key);
        if (res.data.status) {
          openNotificationWithIcon('success', 'Success', 'Documents Uploaded Successfully.');
          setLoading(false);
          onPopupClose();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      console.error(error.response);
    }
  };

  const renderFrontUpload = (props, type) => {
    return (
      <div className="d-flex flex-vertical align-center justify-center">
        <Spacer height={15} />
        <DottedText props={props} text={`${type} page (Bio Data Page) of your International Passport`} />
        <Spacer height={10} />
        {props.src && <AppButton onClick={() => onUploadClick(props?.file)} isActive={true} isBusy={loading} className="primary-color fw500 upload-button action-color-bg full-width" name="Upload" />}
      </div>
    );
  };

  const uploadWrapper = () => (
    <div>
      {stage === 1 && renderFrontUpload(frontProps, 'First')}
      {stage === 2 && renderFrontUpload(backProps, 'Second')}
      {stage === 3 && (
        <div>
          <p className="fw400 faded-text-color text-center font-size-15 text-capitalize">
            Take a Clear <strong>Selfie</strong> of yourself holding your International Passport
          </p>
          <Spacer height={15} />
          <WebcamCapture loading={loading} onSubmitClick={() => onUploadClick(imageFile)} image={image} setImageFile={setImageFile} setImage={setImage} />
        </div>
      )}
    </div>
  );

  return <div className="d-flex flex-vertical align-center justify-center">{uploadWrapper()}</div>;
};

export const DottedText = ({ props, text }) => (
  <>
    <DottedDocumentUpload fileProps={props} clickable />
    <Spacer height={8} />
    <p className="fw500 text-color font-size-13">{text}</p>
  </>
);

export const BvnOrNinPopup = ({ onClose, loading, otpRef, onSubmit }) => {
  const bvnProps = useInput('');
  const ninProps = useInput('');

  useEffect(() => {
    if (bvnProps.value !== '') {
      otpRef.current = { val: bvnProps.value, typ: ninProps.value };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bvnProps.value]);

  return (
    <GenericTabsPopup
      onClose={onClose}
      defaultPosition={1}
      // secondText="NIN (Optional)"
      firstText="BVN"
      firstComponent={(_) => <BvnOrNinInput loading={loading} {...bvnProps} onClick={() => onSubmit(bvnProps.value, ninProps.value)} placeholder="Enter BVN (Compulsory)" />}
      //firstComponent={(_) => <BvnOrNinInput loading={loading} {...bvnProps} onClick={() => onSubmit()} placeholder="Enter BVN (Compulsory)" />}
      // secondComponent={(_) => <BvnOrNinInput loading={loading} {...ninProps} flag placeholder="Enter NIN (Optional)" />}
    />
  );
};

export const BvnNibssPopup = ({ onClose }) => {
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const [loading, setLoading] = useState(false);

  const handleBVNSubmit = async () => {
    setLoading(true);
    try {
      const res = await verifyBVN_NIN(key, { bvn: '22212333122' });
      if (res.status) {
        openNotificationWithIcon('success', 'Success', 'Redirected to NIBSS website');
        setIsBtnClicked(true);
        setLoading(false);
        const nibss_link = res?.data?.data?.consent_url;
        externalRedirect(nibss_link);
      } else {
        openNotificationWithIcon('error', 'Error', 'An error occurred,please try again.');
        setLoading(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Popup onClose={() => onClose()} width="35em" className="fade-in">
      <div className="full-height">
        <p className="fw500 font-size14em text-center">Give Consent</p>
        <div className="d-flex flex-column full-height pt-20">
          <p className="fw200 font-size12em">
            Based on recent CBN guidelines, you are required to visit the <span className="fw700">NIBSS</span> website and grant consent to <span className="fw700">VPay</span> to access your{' '}
            <span className="fw700">BVN</span> data.
          </p>
          <Spacer height={80} />
          {!isBtnClicked && (
            <AppButton
              isBusy={loading}
              isActive={true}
              className="fade-in primary-color fw500 upload-button full-width action-color-bg"
              name="Click Here To Give Consent"
              onClick={() => handleBVNSubmit()}
            />
          )}
          {!isBtnClicked && <small className="fw500 text-vpay-purple text-center">This will open your browser to navigate to the NIBSS website. Return when you are done in order to proceed.</small>}
          {isBtnClicked && <AppButton onClick={() => onClose()} isActive={true} className="fade-in primary-color fw500 upload-button full-width action-color-bg" name="I have given consent" />}
        </div>
      </div>
    </Popup>
  );
};

export const UtilityPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const utilityProps = useFormFile(true);
  const [loading, setLoading] = useState(false);
  const info = useSyncState({
    image_url: '',
    imagebase64: '',
  });

  const onUploadClick = async () => {
    const data = new FormData();
    if (utilityProps.file) {
      data.append('file', utilityProps.file);
    }

    try {
      setLoading(true);
      const res = await uploadFilesToS3('ProofOfAddress', data);
      const fileResponse = res?.data?.data?.file_url;
      if (fileResponse) {
        info.set({
          ...info.get(),
          image_url: fileResponse,
          imagebase64: getBase64String(utilityProps.src),
        });

        const result = await proofOfAddressUpload(info.get(), key);
        if (result.data.status) {
          dispatch(fetchUserMerchant(id));
          openNotificationWithIcon('success', 'Success', 'Document Uploaded Successfully');
          setLoading(false);
          onClose();
        } else {
          openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
        }
        setLoading(false);
      } else {
        openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
        setLoading(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Popup width="40%" className="max-width-600" onClose={onClose}>
      <Spacer height={10} />
      <DocumentTextInfo text="Please choose and upload any of the following documents" />
      <Spacer height={20} />
      <ul className="pp-ul">
        <li>
          Utility bill - Water, Electricity, Rent, Waste bills showing your address <strong>(document should not be more than 3 months old)</strong>
        </li>
        <Spacer height={10} />
        <li>
          Bank statement showing your name & address <strong>(under 1 month old)</strong>{' '}
        </li>
        <Spacer height={10} />
        <li>
          Government-issued document showing your address <strong>(e.g contract letter, tax receipt, land use charges, etc)</strong>
        </li>
      </ul>
      <Spacer height={20} />
      <div className="d-flex j-center full-width">
        <DottedDocumentUpload fileProps={utilityProps} clickable />
      </div>
      <Spacer height={10} />
      {utilityProps?.src && (
        <AppButton onClick={() => onUploadClick(utilityProps.file)} isActive={true} isBusy={loading} className="primary-color fw500 upload-button full-width action-color-bg" name="Upload" />
      )}
    </Popup>
  );
};

export const AMMBANPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const ammBan = useFormFile(true);
  const [loading, setLoading] = useState(false);
  const info = useSyncState({
    doc: '',
  });

  const onUploadClick = async () => {
    const data = new FormData();
    if (ammBan.file) {
      data.append('file', ammBan.file);
    }
    data.append('cloud_name', getCloudNameFromEnv());
    data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
    data.append('api_key', getCloudKeyFromEnv());
    data.append('folder', 'AmmBan');

    try {
      setLoading(true);
      const res = await fetch(getCloudUrlFromEnv(), {
        method: 'POST',
        body: data,
      });
      const fileResponse = await res.json();
      if (fileResponse.secure_url) {
        info.set({
          doc: fileResponse.secure_url,
        });

        const result = await ammbanUpload(info.get(), key);
        if (result.data.status) {
          dispatch(fetchUserMerchant(id));
          openNotificationWithIcon('success', 'Success', 'Document Uploaded Successfully');
          setLoading(false);
          onClose();
        } else {
          openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
        }
        setLoading(false);
      } else {
        openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
        setLoading(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Popup width="40%" className="max-width-600" onClose={onClose}>
      <Spacer height={10} />
      <DocumentTextInfo text="Please Upload Your Ammban Certificate" />
      <Spacer height={20} />
      <div className="d-flex j-center full-width">
        <DottedDocumentUpload fileProps={ammBan} clickable />
      </div>
      <Spacer height={10} />
      {ammBan?.src && <AppButton onClick={() => onUploadClick(ammBan.file)} isActive={true} isBusy={loading} className="primary-color fw500 upload-button full-width action-color-bg" name="Upload" />}
    </Popup>
  );
};

export const CACPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const cac = useFormFile(true);
  const [loading, setLoading] = useState(false);
  const info = useSyncState({
    doc: '',
  });

  const onUploadClick = async () => {
    const data = new FormData();
    if (cac.file) {
      data.append('file', cac.file);
    }
    data.append('cloud_name', getCloudNameFromEnv());
    data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
    data.append('api_key', getCloudKeyFromEnv());
    data.append('folder', 'CAC');

    try {
      setLoading(true);
      const res = await uploadFilesToS3('CAC', data);
      const fileResponse = res?.data?.data?.file_url;
      if (fileResponse) {
        info.set({
          doc: fileResponse,
        });

        const result = await cacUpload(info.get(), key);
        if (result.data.status) {
          dispatch(fetchUserMerchant(id));
          openNotificationWithIcon('success', 'Success', 'Document Uploaded Successfully');
          setLoading(false);
          onClose();
        } else {
          openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
        }
        setLoading(false);
      } else {
        openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
        setLoading(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <Popup width="40%" className="max-width-600" onClose={onClose}>
      <Spacer height={10} />
      <DocumentTextInfo text="Upload your Government Issued Business Document" />
      <Spacer height={20} />
      <div className="d-flex j-center full-width">
        <DottedDocumentUpload fileProps={cac} clickable />
      </div>
      <Spacer height={10} />
      {cac?.src && <AppButton onClick={() => onUploadClick(cac.file)} isActive={true} isBusy={loading} className="primary-color fw500 upload-button full-width action-color-bg" name="Upload" />}
    </Popup>
  );
};

export const OtherDocumentsPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const [fileTitle, setFileTitle] = useState('');
  let others = useFormFile(true, fileTitle);
  const [loading, setLoading] = useState(false);
  const info = useSyncState({
    docs: '',
  });

  useEffect(() => {
    setFileTitle('');
  }, [others.uploadedDocs.length]);

  const onSubmitClick = async () => {
    try {
      setLoading(true);
      info.set({
        docs: JSON.stringify(others?.uploadedDocs),
      });
      const result = await otherUpload(info.get(), key);
      if (result.data.status) {
        dispatch(fetchUserMerchant(id));
        openNotificationWithIcon('success', 'Success', 'Document Uploaded Successfully');
        setLoading(false);
        onClose();
      } else {
        openNotificationWithIcon('error', 'Error', 'Error Uploading Document');
      }
      setLoading(false);
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      setLoading(false);
    }
  };

  const onDeleteClick = async (type) => {
    const deleted = others.uploadedDocs.filter((file) => file.type !== type);
    others.setUploadedDocs(deleted);
  };

  return (
    <Popup width="40%" height="100%" className="d-flex flex-column max-width-600" onClose={onClose} others>
      <div>
        <Spacer height={10} />
        <DocumentTextInfo text="Upload Other Documents" />
        <Spacer height={20} />
        <AppInput type="text" placeholder="Title" value={fileTitle} onChange={(e) => setFileTitle(e.target.value)} />
        <div className="d-flex j-center full-width">
          <DottedDocumentUpload fileProps={others} clickable disabled={fileTitle === '' ? true : false} name="otherdoc" text="Select New File" />
        </div>
        {/* {disabled && <span>Please select a title</span>} */}
        {others.uploadedDocs.length > 0 &&
          others.uploadedDocs.map((file, index) => (
            <div className="d-flex j-space-between mt-20 mb-20 full-width" key={index}>
              <p className="d-flex w-80 mr-2 font-size-16">
                <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.3305 0.0151598C13.2778 0.00520151 13.2235 0 13.1681 0C13.1126 0 13.0584 0.00520151 13.0057 0.0151598H5.5008C2.51604 0.0151598 0 2.527 0 5.59072V18.9564C0 22.1898 2.40101 24.7286 5.5008 24.7286H15.0864C18.0332 24.7286 20.4624 22.0622 20.4624 18.9564V7.51646C20.4624 7.27541 20.3725 7.0437 20.2116 6.86983L14.1324 0.302148C13.9627 0.1188 13.7282 0.0151598 13.4832 0.0151598H13.3305ZM12.2681 1.88081L5.50109 1.88181C3.49534 1.88181 1.80029 3.57402 1.80029 5.59013V18.9558C1.80029 21.1659 3.4032 22.8608 5.50109 22.8608H15.0867C17.0159 22.8608 18.6627 21.0532 18.6627 18.9558L18.6624 8.69269L17.6103 8.69641C17.2102 8.69591 16.755 8.69503 16.249 8.69383C14.1246 8.68918 12.3914 6.96288 12.2744 4.78934L12.2681 4.5548V1.88081ZM17.6772 6.8264L16.2529 6.82661C15.0453 6.82396 14.0681 5.8078 14.0681 4.5548V2.92746L17.6772 6.8264ZM8.86865 11.5211V17.4442L8.87687 17.5709C8.93646 18.0266 9.31302 18.3778 9.76865 18.3778C10.2657 18.3778 10.6687 17.9598 10.6687 17.4442V11.5234L12.5366 13.4692L12.6373 13.5599C12.9891 13.8318 13.4892 13.8028 13.8094 13.472C14.1616 13.1082 14.1628 12.5171 13.8121 12.1517L10.4267 8.62506C10.3418 8.5307 10.2383 8.45463 10.1222 8.40314C9.79331 8.25705 9.3976 8.32362 9.12967 8.60283L5.72407 12.1518L5.63715 12.2567C5.37653 12.6228 5.40672 13.1414 5.72697 13.4721L5.82811 13.5623C6.18105 13.8327 6.68097 13.8013 6.99976 13.4691L8.86865 11.5211Z"
                    fill="#AE207C"
                  />
                </svg>
                {file.type}
              </p>
              <button type="button" className="border-none border-radius5 text-white px-10 c-pointer" style={{ background: '#ab3881' }} onClick={() => onDeleteClick(file.type)}>
                -
              </button>
            </div>
          ))}
      </div>
      {others?.uploadedDocs?.length > 0 && (
        <AppButton
          onClick={onSubmitClick}
          isActive={others?.uploadedDocs?.length > 0}
          isBusy={loading}
          className="fw500 upload-button full-width primary-color action-color-bg margin-top40 "
          name="Submit (All Files Above)"
        />
      )}
    </Popup>
  );
};

export const SharePopup = ({ onClose, url }) => {
  return (
    <Popup width="30%" onClose={onClose} title="Share to">
      <Spacer height={20} />
      <div className="p-relative d-flex align-center share-apps j-center">
        <EmailShareButton url={url}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <FacebookShareButton url={url}>
          <FacebookMessengerIcon size={32} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <TelegramShareButton url={url}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
      <Spacer height={20} />
      <div className="full-width d-flex align-center j-center">
        <div className="p-relative">
          <input className="copy-link-input" disabled readOnly value={url} onChange={() => {}} />
          <CopyToClipboard text={url} onCopy={() => openNotificationWithIcon('success', 'Success', 'Referral Link Copied')}>
            <span className="action-color c-pointer c-link fw400 font-size-14">Copy Link</span>
          </CopyToClipboard>
        </div>
      </div>
    </Popup>
  );
};

export const LoanApplicationInfoPopup = ({ children, title, subtitle, goBack, onClose, textRight, width, noPadding, flag, className, height, others }) => {
  useOverflow();
  return (
    <div className="p-fixed full-height full-width popup fade-in">
      <div className="d-flex j-center">
        <div
          className={`popup-content p-relative ${className || ''}`}
          style={{
            width: width ?? '35%',
            // padding: !noPadding ? '30px' : '0px',
            height: height,
            background: '#f9f9f9',
          }}
        >
          <GradientBackground className="loan-info-grad" justifyContent="flex-start" height={'150px'}>
            <div className="d-flex align-center j-center p-relative">
              <div onClick={() => goBack()} className="c-pointer">
                <img src={back} alt="go back arrow" style={{ position: 'relative', right: '100px' }} />
              </div>
              <h3 className="primary-color fw500">{title}</h3>
            </div>
          </GradientBackground>
          <div className="primary-bg-color p-relative d-flex j-center align-center loan-info-header">
            <div className="text-center w-75">
              <img src={loans} alt="" width="50px" />
              <Spacer height={10} />
              <h3>{subtitle}</h3>
              <p className="loan-subtext">View all the loan details below and accept or decline the offer</p>
            </div>
          </div>
          <div className="loan-info-body">{children}</div>
          {/* {others ? <div className="d-flex flex-column j-space-between full-height">{children}</div> : children} */}
        </div>
      </div>
    </div>
  );
};
