// init firebase
import { initializeApp } from 'firebase/app';
import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const { REACT_APP_PROJECT_ID, REACT_APP_RECAPTCHA_SITE_ID, REACT_APP_API_KEY, REACT_APP_AUTH_DOMAIN, REACT_APP_STORAGE_BUCKET, REACT_APP_MESSAGE_SEND_ID, REACT_APP_APP_ID, REACT_APP_MEASUREMENT_ID } =
  process.env;

const createGoogleRecaptchaV3 = async () => {
  const firebaseConfig = {
    apiKey: REACT_APP_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_MESSAGE_SEND_ID,
    appId: REACT_APP_APP_ID,
    measurementId: REACT_APP_MEASUREMENT_ID,
  };

  const secondApp = initializeApp(firebaseConfig);
  const secondAppCheck = initializeAppCheck(secondApp, {
    provider: new ReCaptchaEnterpriseProvider(REACT_APP_RECAPTCHA_SITE_ID),
  });

  const theToken = await getToken(secondAppCheck);
  if (theToken) {
    return theToken;
  }
  return undefined;
};

export default createGoogleRecaptchaV3;
