export const MerchantActionTypes = {
  FETCH_TRANSACTIONS_START: 'FETCH_TRANSACTIONS_START',
  FETCH_TRANSACTIONS_FAILURE: 'FETCH_TRANSACTIONS_FAILURE',
  FETCH_TRANSACTIONS_SUCCESS: 'FETCH_TRANSACTIONS_SUCCESS',

  FETCH_CUSTOMERS_START: 'FETCH_CUSTOMERS_START',
  FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_FAILURE: 'FETCH_CUSTOMERS_FAILURE',

  FETCH_BALANCE_START: 'FETCH_CUSTOMERS_START',
  FETCH_BALANCE_SUCCESS: 'FETCH_BALANCE_SUCCESS',
  FETCH_BALANCE_FAILURE: 'FETCH_BALANCE_FAILURE',

  FETCH_SUCCESS_CODES: 'FETCH_SUCCESS_CODES',
  FETCH_FAILURE_CODES: 'FETCH_FAILURE_CODES',

  FETCH_FEE_START: 'FETCH_FEE_START',
  FETCH_FEE_SUCCESS: 'FETCH_FEE_SUCCESS',
  FETCH_FEE_FAILURE: 'FETCH_FEE_FAILURE',

  FETCH_CASHPOINTS_START: 'FETCH_CASHPOINTS_START',
  FETCH_CASHPOINTS_SUCCESS: 'FETCH_CASHPOINTS_SUCCESS',
  FETCH_CASHPOINTS_FAILURE: 'FETCH_CASHPOINTS_FAILURE',

  FETCH_PAYOUTS_START: 'FETCH_PAYOUTS_START',
  FETCH_PAYOUTS_SUCCESS: 'FETCH_PAYOUTS_SUCCESS',
  FETCH_PAYOUTS_FAILURE: 'FETCH_PAYOUTS_FAILURE',

  FETCH_CASHPOINT_CUSTOMER_START: 'FETCH_CASHPOINT_CUSTOMER_START',
  FETCH_CASHPOINT_CUSTOMER_SUCCESS: 'FETCH_CASHPOINT_CUSTOMER_SUCCESS',
  FETCH_CASHPOINT_CUSTOMER_FAILURE: 'FETCH_CASHPOINT_CUSTOMER_FAILURE',

  FETCH_MERCHANT_START: 'FETCH_MERCHANT_START',
  FETCH_MERCHANT_SUCCESS: 'FETCH_MERCHANT_SUCCESS',
  FETCH_MERCHANT_FAILURE: 'FETCH_MERCHANT_FAILURE',

  FETCH_BANKS_START: 'FETCH_BANKS_START',
  FETCH_BANKS_SUCCESS: 'FETCH_BANKS_SUCCESS',
  FETCH_BANKS_FAILURE: 'FETCH_BANKS_FAILURE',

  FETCH_CASHIER_BANKS_START: 'FETCH_CASHIER_BANKS_START',
  FETCH_CASHIER_BANKS_SUCCESS: 'FETCH_CASHIER_BANKS_SUCCESS',
  FETCH_CASHIER_BANKS_FAILURE: 'FETCH_CASHIER_BANKS_FAILURE',

  UPDATE_CURRENT_CASHIER: 'UPDATE_CURRENT_CASHIER',

  DELETE_CASHPOINT_START: 'DELETE_CASHPOINT_START',
  DELETE_CASHPOINT_SUCCESS: 'DELETE_CASHPOINT_SUCCESS',
  DELETE_CASHPOINT_FAILURE: 'DELETE_CASHPOINT_FAILURE',

  ADD_CASHPOINT_START: 'ADD_CASHPOINT_START',
  ADD_CASHPOINT_SUCCESS: 'ADD_CASHPOINT_SUCCESS',
  ADD_CASHPOINT_FAILURE: 'ADD_CASHPOINT_FAILURE',

  VERIFY_BANK_START: 'VERIFY_BANK_START',
  VERIFY_BANK_SUCCESS: 'VERIFY_BANK_SUCCESS',
  VERIFY_BANK_FAILURE: 'VERIFY_BANK_FAILURE',

  VERIFY_CASHIER_BANK_START: 'VERIFY_CASHIER_BANK_START',
  VERIFY_CASHIER_BANK_SUCCESS: 'VERIFY_CASHIER_BANK_SUCCESS',
  VERIFY_CASHIER_BANK_FAILURE: 'VERIFY_CASHIER_BANK_FAILURE',

  UPDATE_BANK_START: 'UPDATE_BANK_START',
  UPDATE_BANK_SUCCESS: 'UPDATE_BANK_SUCCESS',
  UPDATE_BANK_FAILURE: 'UPDATE_BANK_FAILURE',

  ADD_CASHIER_BANK_DETAILS_START: 'ADD_CASHIER_BANK_DETAILS_START',
  ADD_CASHIER_BANK_DETAILS_SUCCESS: 'ADD_CASHIER_BANK_DETAILS_SUCCESS',
  ADD_CASHIER_BANK_DETAILS_FAILURE: 'ADD_CASHIER_BANK_DETAILS_FAILURE',

  FETCH_BENEFICIARIES_SUCCESS: 'FETCH_BENEFICIARIES_SUCCESS',

  ADD_MERCHANT_START: 'ADD_MERCHANT_START',
  ADD_MERCHANT_SUCCESS: 'ADD_MERCHANT_SUCCESS',
  ADD_MERCHANT_FAILURE: 'ADD_MERCHANT_FAILURE',

  PAY_AIRTIME_START: 'AIRTIME_START',
  PAY_AIRTIME_SUCCESS: 'AIRTIME_SUCCESS',
  PAY_AIRTIME_FAILURE: 'AIRTIME_FAILURE',

  PAY_DATA_START: 'DATA_START',
  PAY_DATA_SUCCESS: 'DATA_SUCCESS',
  PAY_DATA_FAILURE: 'DATA_FAILURE',

  GET_PROVIDER_START: 'PROVIDER_START',
  GET_PROVIDER_SUCCESS: 'PROVIDER_SUCCESS',
  GET_PROVIDER_FAILURE: 'PROVIDER_FAILURE',

  GET_PACKAGES_START: 'PACKAGES_START',
  GET_PACKAGES_SUCCESS: 'PACKAGES_SUCCESS',
  GET_PACKAGES_FAILURE: 'PACKAGES_FAILURE',

  PAY_CABLE_START: 'PAY_CABLE_START',
  PAY_CABLE_SUCCESS: 'PAY_CABLE_SUCCESS',
  PAY_CABLE_FAILURE: 'PAY_CABLE_FAILURE',

  PAY_UTILITY_START: 'PAY_UTILITY_START',
  PAY_UTILITY_SUCCESS: 'PAY_UTILITY_SUCCESS',
  PAY_UTILITY_FAILURE: 'PAY_UTILITY_FAILURE',

  GET_CABLE_START: 'GET_CABLE_START',
  GET_CABLE_SUCCESS: 'GET_CABLE_SUCCESS',
  GET_CABLE_FAILURE: 'GET_CABLE_FAILURE',

  GET_UTILITY_START: 'GET_UTILITY_START',
  GET_UTILITY_SUCCESS: 'GET_UTILITY_SUCCESS',
  GET_UTILITY_FAILURE: 'GET_UTILITY_FAILURE',

  UPLOAD_CASHIER_PROFILE_PICTURE_START: 'UPLOAD_CASHIER_PROFILE_PICTURE_START',
  UPLOAD_CASHIER_PROFILE_PICTURE_SUCCESS: 'UPLOAD_CASHIER_PROFILE_PICTURE_SUCCESS',
  UPLOAD_CASHIER_PROFILE_PICTURE_FAILURE: 'UPLOAD_CASHIER_PROFILE_PICTURE_FAILURE',

  CHECK_LOAN_ELIGIBILITY_START: 'CHECK_LOAN_ELIGIBILITY_START',
  CHECK_LOAN_ELIGIBILITY_SUCCESS: 'CHECK_LOAN_ELIGIBILITY_SUCCESS',
  CHECK_LOAN_ELIGIBILITY_FAILURE: 'CHECK_LOAN_ELIGIBILITY_FAILURE',

  SUBMIT_LOAN_REQUEST_START: 'SUBMIT_LOAN_REQUEST_START',
  SUBMIT_LOAN_REQUEST_SUCCESS: 'SUBMIT_LOAN_REQUEST_SUCCESS',
  SUBMIT_LOAN_REQUEST_FAILURE: 'SUBMIT_LOAN_REQUEST_FAILURE',

  GET_LOAN_INTEREST_RATES_START: 'GET_LOAN_INTEREST_RATES_START',
  GET_LOAN_INTEREST_RATES_SUCCESS: 'GET_LOAN_INTEREST_RATES_SUCCESS',
  GET_LOAN_INTEREST_RATES_FAILURE: 'GET_LOAN_INTEREST_RATES_FAILURE',

  VERIFY_AGGREGATOR_CODE_START: 'VERIFY_AGGREGATOR_CODE_START',
  VERIFY_AGGREGATOR_CODE_SUCCESS: 'VERIFY_AGGREGATOR_CODE_SUCCESS',
  VERIFY_AGGREGATOR_CODE_FAILURE: 'VERIFY_AGGREGATOR_CODE_FAILURE',

  FETCH_LOAN_HISTORY_START: 'FETCH_LOAN_HISTORY_START',
  FETCH_LOAN_HISTORY_SUCCESS: 'FETCH_LOAN_HISTORY_SUCCESS',
  FETCH_LOAN_HISTORY_FAILURE: 'FETCH_LOAN_HISTORY_FAILURE',

  FETCH_WARRANTER_LOAN_REQUESTS_START: 'FETCH_WARRANTER_LOAN_REQUESTS_START',
  FETCH_WARRANTER_LOAN_REQUESTS_SUCCESS: 'FETCH_WARRANTER_LOAN_REQUESTS_SUCCESS',
  FETCH_WARRANTER_LOAN_REQUESTS_FAILURE: 'FETCH_WARRANTER_LOAN_REQUESTS_FAILURE',

  FETCH_LOAN_CREDIT_LIMIT_START: 'FETCH_LOAN_CREDIT_LIMIT_START',
  FETCH_LOAN_CREDIT_LIMIT_SUCCESS: 'FETCH_LOAN_CREDIT_LIMIT_SUCCESS',
  FETCH_LOAN_CREDIT_LIMIT_FAILURE: 'FETCH_LOAN_CREDIT_LIMIT_FAILURE',

  WARRANTER_LOAN_APPROVAL_START: 'WARRANTER_LOAN_APPROVAL_START',
  WARRANTER_LOAN_APPROVAL_SUCCESS: 'WARRANTER_LOAN_APPROVAL_SUCCESS',
  WARRANTER_LOAN_APPROVAL_FAILURE: 'WARRANTER_LOAN_APPROVAL_FAILURE',

  FETCH_NEXT_LOAN_REPAYMENT_START: 'FETCH_NEXT_LOAN_REPAYMENT_START',
  FETCH_NEXT_LOAN_REPAYMENT_SUCCESS: 'FETCH_NEXT_LOAN_REPAYMENT_SUCCESS',
  FETCH_NEXT_LOAN_REPAYMENT_FAILURE: 'FETCH_NEXT_LOAN_REPAYMENT_FAILURE',

  FETCH_GUARANTOR_TYPES_START: 'FETCH_GUARANTOR_TYPES_START',
  FETCH_GUARANTOR_TYPES_SUCCESS: 'FETCH_GUARANTOR_TYPES_SUCCESS',
  FETCH_GUARANTOR_TYPES_FAILURE: 'FETCH_GUARANTOR_TYPES_FAILURE',

  FETCH_ALTERNATE_ACCOUNT_FEES_START: 'FETCH_ALTERNATE_ACCOUNT_FEES_START',
  FETCH_ALTERNATE_ACCOUNT_FEES_SUCCESS: 'FETCH_ALTERNATE_ACCOUNT_FEES_SUCCESS',
  FETCH_ALTERNATE_ACCOUNT_FEES_FAILURE: 'FETCH_ALTERNATE_ACCOUNT_FEES_FAILURE',

  TEMP_STORE_PIN: 'TEMP_STORE_PIN',
  CLEAR_STORED_PIN: 'CLEAR_STORED_PIN',
};
