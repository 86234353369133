import React from 'react';

import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import AppNav from '../../components/app_nav/app_nav';
import { CardDropDown } from '../../components/card_dropdown/card_dropdown';
import { Spacer } from '../../components/layout/layout';
import { FallbackUI, MerchantHeader, Support } from '../../components/merchant_widgets/merchant_widgets';
import { RedAlert, RedAlertWithLink } from '../../components/red_alert/red_alert';
import { TransactionIdProvider } from '../../contexts/transactionIdContext';
import useHasSupport from '../../hooks/use_has_support';
import { useMenuRender } from '../../hooks/use_menu_render';
import useVerifiedUser from '../../hooks/use_user_verified';
import { fetchFees } from '../../redux/merchant/merchant_actions';
import { DashboardLayout } from '../cashpoint_desktop/cashpoint_dashboard/cashpoint_dashboard';
import { DashBoardWrapper } from '../cashpoint_desktop/cashpoint_wrapper/cashpoint_wrapper';
import Authorizations from './authorizations';
import MerchantBulkPayment from './bulk_payment';
import MerchantDashboardPage from './dashboard';
import { LoanProvider } from './loan/context';
import LoyaltyScheme from './loyalty_scheme';
import MerchantVirtualAccounts from './virtual_accounts';
import WebFailedOrders from './web_failed_orders';

// Lazy loaded components for performance

const MerchantCompliance = React.lazy(() => import('./compliance'));
const MerchantCashpoint = React.lazy(() => import('./cashpoint'));
const MerchantTransaction = React.lazy(() => import('./transaction'));
const Settings = React.lazy(() => import('./settings'));
const Referral = React.lazy(() => import('./referral'));
const Bonus = React.lazy(() => import('./bonus'));
const Loan = React.lazy(() => import('./loan'));
const Affiliate = React.lazy(() => import('./affilate'));

export default function MerchantDashboard() {
  const history = useHistory();
  const TEXT = (
    <>
      Please click on the{' '}
      <span className="c-pointer link-color" onClick={() => history.push({ pathname: '/admin/merchant/settings', state: { tab: 'Payout Accounts' } })}>
        Settings
      </span>{' '}
      menu and setup your payout bank account in order to withdraw your customer payments.
    </>
  );

  const { links = {}, keys } = useMenuRender();
  const dispatch = useDispatch();
  const openRedMessage = useSelector((state) => state.merchant.openRedMessage);
  const inbound = useSelector((state) => state.merchant.inboundFee);
  const outbound = useSelector((state) => state.merchant.outboundFee);
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();

  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const currentMerchant = useSelector((state) => state.user.currentMerchant);

  const hasSupport = useHasSupport();

  const BOTTOM_CARD_TEXT = (
    <>
      {' '}
      Pay as low as {inbound}% only for inbound Transfer, and ₦{outbound} for Outbound Transfer
    </>
  );

  useEffect(() => {
    dispatch(fetchFees(key, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMerchant]);

  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  return (
    <DashBoardWrapper background="#F8F8F8">
      <div className="no-mobile">
        <AppNav BOTTOM_CARD_TEXT={BOTTOM_CARD_TEXT} links={links} navKeys={keys} beforeNavWidget={<CardDropDown />} />
      </div>
      <TransactionIdProvider>
        <DashboardLayout header={<MerchantHeader BOTTOM_CARD_TEXT={BOTTOM_CARD_TEXT} links={links} navKeys={keys} beforeNavWidget={<CardDropDown />} />}>
          <LoanProvider>
            {openRedMessage && <RedAlert text={TEXT} />}
            {(hasBVNorNIN && isVerified) || hasBeenVerified ? '' : <RedAlertWithLink text="Activate Your Account" lowerText="Verify Your Business Account To Enjoy All VPAY Features" />}
            <Suspense fallback={<FallbackUI />}>
              <Switch>
                <Route exact path="/admin/merchant" component={MerchantDashboardPage} />
                <Route exact path="/admin/merchant/compliance" component={MerchantCompliance} />
                <Route exact path="/admin/merchant/cashpoint" component={MerchantCashpoint} />
                <Route exact path="/admin/merchant/loans" component={Loan} />
                <Route exact path="/admin/merchant/virtual-accounts" component={MerchantVirtualAccounts} />
                <Route exact path="/admin/merchant/bulk" component={MerchantBulkPayment} />
                <Route exact path="/admin/merchant/history" component={MerchantTransaction} />
                <Route exact path="/admin/merchant/settings" component={Settings} />
                <Route exact path="/admin/merchant/referral" component={Referral} />
                <Route exact path="/admin/merchant/bonus" component={Bonus} />
                <Route exact path="/admin/merchant/affliates" component={Affiliate} />
                <Route exact path="/admin/merchant/authorizations" component={Authorizations} />
                <Route exact path="/admin/merchant/loyalty" component={LoyaltyScheme} />
                <Route exact path="/admin/merchant/failed_web_orders" component={WebFailedOrders} />
              </Switch>
            </Suspense>
            {hasSupport && <Support />}
          </LoanProvider>
          <Spacer height={20} />
        </DashboardLayout>
      </TransactionIdProvider>
    </DashBoardWrapper>
  );
}
