export const baseUrl = process.env.REACT_APP_ENV === 'dev' ? `https://pluto.vpay.africa/` : `https://saturn.vpay.africa/`;
export const webhookPushBaseUrl = process.env.REACT_APP_ENV === 'dev' ? `https://pluto.vpay.africa/` : `https://services2.vpay.africa/`;
export const socketListnerUrl = process.env.REACT_APP_ENV === 'dev' ? 'https://tiger.vpay.africa/' : 'https://plus.vpay.africa/';
export const baseQueryUrl = `${baseUrl}service/demo/v1/query/`;

export const cashpointBaseUrl = `${baseUrl}service/demo/v1/query/cashpoint/`;
export const loginUrl = `${baseUrl}service/demo/v1/query/user/login`;
export const startSignupUrl = `${baseUrl}service/demo/v1/query/user/add`;
export const completeRegUrl = `${baseUrl}service/demo/v1/query/merchant/retail/add`;
export const merchantUrl = `${baseUrl}service/demo/v1/query/merchant/`;
export const cashpointUrl = `${baseUrl}service/demo/v1/query/merchant/cashpoint/add`;
export const cashpointCustomersUrl = `${baseUrl}service/demo/v1/query/cashpoint/`;
export const contactSupportUrl = `${baseUrl}service/demo/v1/query/messaging/support/send`;
export const fetchMerchantUrl = `${baseUrl}service/demo/v1/query/merchant`;
export const createTnxUrl = `${baseUrl}service/demo/v1/query/cashpoint/retail/order/add`;
export const changePasswordUrl = `${baseUrl}service/demo/v1/query/user/changepassword`;
export const validateChangePasswordUrl = `${baseUrl}service/demo/v1/query/user/changepassword/initial`;
export const resetPwrdUrl = `${baseUrl}service/demo/v1/query/user/forgotpassword`;
export const forgotPasswordInitialUrl = `${baseUrl}service/demo/v1/query/user/forgotpassword/initial`;
export const currentUserUrl = `${baseUrl}service/demo/v1/query/merchant/whoami`;
export const bankNameUrl = `${baseUrl}service/demo/v1/query/merchant/bank/name/show`;
export const addBankDetailsUrl = `${baseUrl}service/demo/v1/query/merchant/bank/add`;
export const banksListUrl = `${baseUrl}service/demo/v1/query/merchant/bank/list/show`;
export const cashpointDeactivateUrl = `${baseUrl}service/demo/v1/query/merchant/cashpoint/deactivate`;
export const getMerchantCurrentLimitUrl = `${baseQueryUrl}customer/credit-limit`;
export const submitMerchantCurrentLimitUrl = `${baseQueryUrl}customer/credit-limit-upgrade-request`;

export const bvn_nin_url = `${baseUrl}service/demo/v1/query/merchant/document/verifyBVN_NIN`;
export const bvn_otp_url_confirm = `${baseUrl}service/demo/v1/query/merchant/document/confirmBVNOTP`;
export const certificateOfBusinessUrl = `${baseUrl}service/demo/v1/query/merchant/document/certificateOfBusiness`;
export const proofOfAddressUrl = `${baseUrl}service/demo/v1/query/merchant/mobile/document/proofOfAddressUploader`;
export const ammbanUrl = `${baseUrl}service/demo/v1/query/merchant/document/uploadammbancert`;
export const cacUrl = `${baseUrl}service/demo/v1/query/merchant/document/uploadcaccert`;
export const otherUrl = `${baseUrl}service/demo/v1/query/merchant/document/uploadotherdocuments`;
export const idCardUrl = `${baseUrl}service/demo/v1/query/merchant/document/idCard/show`;
export const internationalPassportUrl = `${baseUrl}service/demo/v1/query/merchant/mobile/document/internationalPassportUploader`;
export const idCardUploadUrl = `${baseUrl}service/demo/v1/query/merchant/mobile/document/idCardUpload`;
export const passportPhotographUploaderUrl = `${baseUrl}service/demo/v1/query/merchant/document/passportPhotographUploader`;
export const proofOfAddressUploaderUrl = `${baseUrl}service/demo/v1/query/merchant/document/proofOfAddressUploader`;
export const proofOfAddressDeleteUrl = `${baseUrl}service/demo/v1/query/merchant/document/proofOfAddress/delete/61017ccf03bb1b1040660b4b`;
export const idCardDelete = `${baseUrl}service/demo/v1/query/merchant/document/idCard/delete/61017dad03bb1b1040660b6d`;
export const complianceLivelinessVideoUrl = `${baseUrl}service/demo/v1/query/merchant/document/uploadlivenessvideo`;

export const referralUrl = `${baseUrl}service/demo/v1/query/merchant/`;
export const industryUrl = `${baseUrl}service/demo/v1/query/merchant/industry/list/show`;

export const referralWithdrawUrl = `${baseUrl}service/demo/v1/query/referral/bonus/withdraw`;

export const transactionDetailUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/show`;

export const fetchCashierBanksUrl = `${baseUrl}service/demo/v1/query/cashpoint/bank/list/show`;
export const addCashierBankDetailsUrl = `${baseUrl}service/demo/v1/query/cashpoint/bank/add`;
export const uploadCashierProfilePictureUrl = `${baseUrl}service/demo/v1/query/merchant/document/fileuploader`;
export const cashierBankNameUrl = `${baseUrl}service/demo/v1/query/cashpoint/bank/name/show`;
export const webhookUrl = `${baseUrl}service/demo/v1/query/merchant/webhook/add`;
export const webhookRepushUrl = `${webhookPushBaseUrl}service/demo/v1/query/merchant`;

export const bonusBalanceUrl = `${baseUrl}service/demo/v1/query/aggregator/walletbalance/show`;
export const downlinesUrl = `${baseUrl}service/demo/v1/query/aggregator/downline/show`;
export const addAggregatorUrl = `${baseUrl}service/demo/v1/query/aggregator/code/add`;
export const bonusTransactionsUrl = `${baseUrl}service/demo/v1/query/aggregator/transactions/show`;
export const bonusWithdrawUrl = `${baseUrl}service/demo/v1/query/aggregator/wallet/withdraw`;
export const aggregatorMemberPayoutUrl = `${baseUrl}service/demo/v1/query/aggregator/member`;

export const updateWebUrl = `${baseUrl}api/v1/webintegration/classification/update/web`;
export const sandboxAticvate = `${baseUrl}api/v1/webintegration/sandbox/activate`;

export const disputeTypeUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/displayalldisputetype`;
export const createDisputeUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/createdispute`;
export const reverseTransactionUrl = `${baseUrl}service/demo/v1/query/aggregator/challenge/transaction`;
export const takeSelfieUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/selfie/upload`;

export const confirmUSSDUrl = `${baseUrl}service/demo/v1/query/aggregator/challenge/transaction`;

export const sendCrashReportUrl = `${baseUrl}service/demo/v1/query/messaging/sendWebCrashMailToAdmin/`;

export const merchantAddBulkPaymentGroup = `${socketListnerUrl}api/service/v1/query/bulkpayment/group/add`;
export const merchantAddBulkBeneficiaryUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/beneficiary/add`;
export const merchantPayGroupUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/group/pay`;

export const getAllMerchantPaymentGroupUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/group/list`;
export const getAllMerchantPaymentGroupBeneficiariesUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/beneficiary/list`;
export const uploadBeneficiariesExcelListUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/beneficiaries/upload`;
export const uploadFileStatusUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/beneficiaries/upload/status`;
export const paymentStatusUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/group/pay/status`;
export const deleteBeneficiariesUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/beneficiary`;
export const deletePaymentGroupUrl = `${socketListnerUrl}api/service/v1/query/bulkpayment/group`;

export const loanUrl = `${baseUrl}service/demo/v1/query/loan`;
export const getOnePaymentGroup = `${socketListnerUrl}api/service/v1/query/bulkpayment/group/`;

export const getInboundTnxsCountUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/inbound/count`;

export const getInboundTnxsCountOnlyUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/inbound/countonly`;
export const dropinUrl = `${baseUrl}api/v1/webintegration/`;

export const cashpointAlternateAccountURL = `${baseUrl}service/demo/v1/query/merchant/otherbanks/virtualaccount/update`;
export const getAlternateAccountFeesURL = `${baseUrl}service/demo/v1/query/merchant/wallet/fees`;
export const getMerchantRolesListUrl = `${baseUrl}service/demo/v1/query/merchant/user/role/list`;
export const getMerchantTeamListUrl = `${baseUrl}service/demo/v1/query/merchant/user/role/show/all`;
export const uploadFileToS3Url = `${baseUrl}service/demo/v1/query/upload-to-s3`;
export const addTeamMembersUrl = `${baseUrl}service/demo/v1/query/merchant/user/role/add`;
export const updateTeamMemberUrl = `${baseUrl}service/demo/v1/query/merchant/user/`;
export const lookupCashpointUrl = `${baseUrl}service/demo/v1/query/merchant/cashpoint/name/lookup`;

export const affilatedashboardURL = 'https://alphaam.vpay.africa/auth/verify?';
export const addOutboundCategoryUrl = `${baseUrl}service/demo/v1/query/category/outbound/add`;
export const getCategoryListUrl = `${baseUrl}service/demo/v1/query/category/outbound/list/show`;
export const getMerchantProductCategoryUrl = `${baseUrl}service/demo/v1/query/category/product/list/show`;
export const getAllCategoriesUrl = `${baseUrl}service/demo/v1/query/category/product/category/all`;
export const addProductCategoryUrl = `${baseUrl}service/demo/v1/query/category/product/add`;

export const submitCustomInboundUrl = `${baseUrl}service/demo/v1/query/merchant/transaction/custom/inbound/add`;
export const submitIncomeCategoryUrl = `${baseUrl}service/demo/v1/query/category/inbound/choose`;
export const getLivelinessKeyUrl = `${baseUrl}service/demo/v1/query/merchant/liveliness/question/show`;

export const storefrontWaitlistUrl = `${baseUrl}service/demo/v1/query/user/storefront/wishlist`;

export const generateCashpointTnxHistoryUrl = `${baseUrl}service/demo/v1/query/cashpoint/txn`;
export const getFailedWebOrderTnxsUrl = `${baseUrl}service/demo/v1/query/merchant/failedwebtransfer/transaction/list/show`;
