import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tabs, Tag, Timeline } from 'antd';
import { MerchantContentWrapper } from '../../components/merchant_widgets/merchant_widgets';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButton, { AppButtonWithIcon } from '../../components/app_button/app_button';
import { ContactCards } from '../../components/contact_cards/contact_cards';
import { FormDropdown } from '../../components/form_dropdown/form_dropdown';
import { FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import { useInput } from '../../hooks/use_input';
import { closeRedMessage, fetchUserMerchant, updateBankDetails, verifyBankFailure, verifyMerchantBankDetails } from '../../redux/merchant/merchant_actions';
import { addWebhook, fetchMerchantKey, getMerchantRolesList, getMerchantTeamList, toggleFundDestination, updateSandbox, updateWeb } from '../../services/apiSevices';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import { Profile } from './profile';

import cardOverlay from '../../assets/images/cardOverlay.svg';
import teamIcon from '../../assets/images/people.svg';

import success from '../../assets/images/success.svg';
import warning from '../../assets/images/warning.svg';

import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AppDivider from '../../components/app_divider/app_divider';
import Popup from '../../components/popup/popup';
import { MobileRedAlertWithLink } from '../../components/red_alert/red_alert';
import { AddTeamMemberPopup, EmptyTeam, MobileMemberDetails, MobileTeamTableDesktop, RemoveMember, TeamRoles, TeamTableDesktop, UpdateTeamMember } from '../../components/team_table/team_table';
import { useFeatureFlag } from '../../hooks/use_feature_flag';
import useVerifiedUser from '../../hooks/use_user_verified';
import { maskData } from '../../utils/helper';
import './index.css';

const Settings = () => {
  const { state } = useLocation();
  const role = useSelector((state) => state.user.role);
  const { isFlagRequired } = useFeatureFlag('web.team');
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();

  const TabItems = [
    {
      label: 'Profile',
      key: 'Profile',
      children: (
        <div className="full-height flex-vertical full-width d-flex align-center j-center">
          <Spacer height={25} />
          <Profile />
        </div>
      ),
      disabled: false,
    },
    {
      label: 'Payout Accounts',
      key: 'Payout Accounts',
      children: (
        <div className="full-height flex-vertical full-width d-flex align-center j-center">
          <Spacer height={25} />
          <PayoutAccount />
        </div>
      ),
      disabled: false,
    },
    {
      label: 'API',
      key: 'API',
      children: (
        <div className="full-height flex-vertical full-width d-flex align-center j-center">
          <Spacer height={25} />
          <API />
        </div>
      ),
      disabled: role === 'merchant-support' ? true : false,
    },
    {
      label: 'Support',
      key: 'Support',
      children: <SupportPage center />,
      disabled: false,
    },
    isFlagRequired && {
      label: 'Teams',
      key: 'Teams',
      children: (
        <div className="full-height flex-vertical full-width d-flex align-center j-center">
          <div className="no-mobile-but-desktop">
            <Spacer height={25} />
          </div>
          <div className="no-desktop-but-mobile">
            <Spacer height={10} />
          </div>
          <Teams />
        </div>
      ),
      disabled: false,
    },
    {
      label: 'Webhook',
      key: 'Webhook',
      children: <Webhook />,
      disabled: role === 'merchant-support' ? true : false,
    },
  ];

  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  return (
    <MerchantContentWrapper className="full-height-minus-150 fade-in tab-settings fw400">
      {(hasBVNorNIN && isVerified) || hasBeenVerified ? '' : <MobileRedAlertWithLink text="Activate Your Account" lowerText="Verify Your Business Account To Enjoy All VPAY Features" />}
      <Tabs defaultActiveKey={state?.tab || 'Profile'} items={TabItems} />
    </MerchantContentWrapper>
  );
};

export const SupportPage = ({ center }) => {
  return (
    <div className={`full-height flex-vertical full-width d-flex align-center ${center ? 'j-center' : ''}`}>
      <MerchantSupport>
        <h3 className="no-margin fw700 primary-color align-self-center font-size-25">Get In Touch</h3>
        <span className="fw400 font-size-20 mobile-font-size-13 primary-color text-center">Welcome! We’re here to help you get started with VPay.</span>
        <span className="fw400 font-size-20 mobile-font-size-13 primary-color text-center">Start a live chat conversation if you have any questions or contact us.</span>
      </MerchantSupport>
      <div className="d-grid card-grid">
        <ContactCards height="345px" width="320px" />
      </div>
    </div>
  );
};

export default Settings;

export const getBankName = (code, banks) => {
  let foundBank = banks.find((bank) => bank.code === code);
  return foundBank ? foundBank.name : '';
};

export const getBankCode = (name, banks) => {
  let foundBank = banks.find((bank) => bank.name === name);
  return foundBank ? foundBank.code : '';
};

export const PayoutAccount = () => {
  const banks = useSelector((state) => state.merchant.banks);
  const isBusy = useSelector((state) => state.merchant.loading);
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const isBankDataVerified = useSelector((state) => state.merchant.isBankDataVerified);
  const verifiedBankName = useSelector((state) => state.merchant.verifiedBankName);
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  const mappedBanks = banks.map((bank) => bank.name);
  const defaultAccountNumber = merchantDetails.bank ? merchantDetails.bank.number : '';
  const defaultBank = merchantDetails.bank ? getBankName(merchantDetails.bank.code, banks) : '';
  const accountNumberProps = useInput(defaultAccountNumber);

  const [bank, setBank] = useState(defaultBank);
  const [checked, setChecked] = useState(merchantDetails.keepfundsinwallet);
  const dispatch = useDispatch();

  const onChange = (e) => {
    setBank(e);
  };

  const onSave = () => {
    if (isBankDataVerified && bank && accountNumberProps.value) {
      const data = {
        bankname: bank,
        accountnumber: accountNumberProps.value,
        bankcode: getBankCode(bank, banks),
        merchant: merchantID,
      };
      dispatch(updateBankDetails(data));
    }
    dispatch(closeRedMessage);
  };

  const handleSwitchToggle = async () => {
    try {
      setChecked((checked) => !checked);
      await toggleFundDestination(merchantID);
      dispatch(fetchUserMerchant(merchantID));
    } catch (error) {
      openNotificationWithIcon(true, 'error', error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!merchantDetails.bank) {
      dispatch(verifyBankFailure());
    }
    dispatch(fetchUserMerchant(merchantID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bank && accountNumberProps.value) {
      if (accountNumberProps.value.length === 10) {
        const data = {
          accountnumber: accountNumberProps.value,
          bankcode: getBankCode(bank, banks),
        };
        dispatch(verifyMerchantBankDetails(data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank, accountNumberProps.value]);

  return (
    <>
      <div className="profile-wrapper fw400 primary-bg-color p-relative" style={{ width: '45%' }}>
        <Spacer height={30} />
        <p className="fw500 font-size-18 text-color">Enter Business Bank Account</p>
        <Spacer height={30} />
        <FormDropdown name="Search Bank" onChange={onChange} defaultValue={defaultBank} options={mappedBanks} label="Account Provider" className="full-width fw400" />
        <FormInput label="Account Number" {...accountNumberProps} className="important-full-width fw400" />
        {isBankDataVerified && <FormInput label="Account Name" disabled readonly value={verifiedBankName} className="important-full-width fw400" />}
        {isBusy && !isBankDataVerified && <LoadingOutlined />}
        {/* <div className="full-width d-flex align-center">
          <Switch checked={checked} onChange={handleSwitchToggle} />
          <Spacer width={20} />
          <span className="text-color fw400 font-size-14 opacity-5">Keep funds in VPay account?</span>
        </div> */}
        <Spacer height={20} />
      </div>
      <Spacer height={20} />
      {merchantDetails?.bank && merchantDetails.bank?.underreview && <UnderReview />}
      {merchantDetails?.bank && merchantDetails.bank?.underreview && <Spacer height={20} />}
      <div className="save-wrapper">
        <AppButton isActive={true} isBusy={isBusy} onClick={onSave} className="font-size-14 b-tn-padding action-color-bg fw400 full-width primary-color" name="Save" />
      </div>
    </>
  );
};

export const API = () => {
  const dispatch = useDispatch();
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const { has_requested_web_activation = false, sandbox_activated = false, live_mode = false, classification = '', service } = useSelector((state) => state.merchant.merchantDetails);
  const [secret, setSecret] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDev, setOpenDev] = useState(false);
  const [openSanboxPopup, updateSanboxPopup] = useState(false);
  const [loadPopup, setLoadPopup] = useState(false);

  const isSandbox = useMemo(() => {
    if (service === 'pluto.vpay.africa') return true;
    return false;
  }, [service]);

  const onClick = async () => {
    try {
      setLoading(true);
      const res = await fetchMerchantKey(id);
      setSecret(res.data.privatekey);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return openNotificationWithIcon('error', 'Error', error.response?.message ?? 'Error fetching key');
    }
  };

  const HANDLE_CLICK = async () => {
    try {
      setLoadPopup(true);
      const res = await updateWeb(publickey);
      //console.log(res.data);
      openNotificationWithIcon('success', 'Success', res.data?.message ?? '');
      setOpenDev(false);
      setLoadPopup(false);
      dispatch(fetchUserMerchant(id));
    } catch (error) {
      setLoadPopup(false);
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'Error');
    }
  };

  const enableSandbox = async () => {
    try {
      setLoadPopup(true);
      const res = await updateSandbox(publickey);
      //console.log(res.data);
      openNotificationWithIcon('success', 'Success', res.data?.message ?? '');
      setLoadPopup(false);
      updateSanboxPopup(false);
      dispatch(fetchUserMerchant(id));
    } catch (error) {
      setLoadPopup(false);
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'Error');
    }
  };

  return (
    <div className="profile-wrapper primary-bg-color p-relative" style={{ width: '60%' }}>
      {classification === 'web' && (
        <Tag className="mode-tag" color={!isSandbox ? 'success' : 'warning'}>
          {!isSandbox ? 'Live Account' : 'Sandbox Account'}
        </Tag>
      )}
      <Spacer height={40} />
      <div className="d-flex full-width align-center j-center">
        <p className="fw500 text-color font-size-18">API Configuration</p>
      </div>
      <Spacer height={20} />
      <div className="d-flex flexCol align-baseline j-center p-relative">
        <span className="fw400 text-color mobile-full-width font-size-14 width-20-percent">Public Key</span>
        <Spacer width={10} />
        <FormInput disabled readOnly value={publickey} className="important-full-width fw400 api-input" />
        <CopyToClipboard text={publickey} onCopy={() => openNotificationWithIcon('success', 'Success', 'Public key copied')}>
          <span className="s-c c-pointer primary-color fw400 important-p-absolute copy-secret">Copy Key</span>
        </CopyToClipboard>
      </div>
      <div className="d-flex flexCol align-baseline j-center p-relative">
        <span className="fw400 text-color mobile-full-width font-size-14 width-20-percent">Secret Key</span>
        <Spacer width={10} />
        <FormInput value={maskData(secret)} disabled readOnly className="important-full-width fw400 api-input" />
        {secret === '' && <AppButton isBusy={loading} onClick={onClick} isActive={true} className="s-c primary-color fw400 important-p-absolute copy-secret" name="Get Key" />}
        {secret !== '' && (
          <CopyToClipboard text={secret} onCopy={() => openNotificationWithIcon('success', 'Success', 'Secret key copied')}>
            <span className="s-c c-pointer text-center primary-color fw400 important-p-absolute copy-secret">Copy Key</span>
          </CopyToClipboard>
        )}
      </div>
      <Spacer height={20} />
      <AppDivider />
      <Spacer height={40} />
      {classification !== 'pos' && !isSandbox && (
        <div className="d-flex align-center justify-start">
          <Timeline>
            <Timeline.Item
              dot={!has_requested_web_activation ? <ExclamationCircleOutlined className="font-size-17" /> : <CheckCircleOutlined className="font-size-17" />}
              color={!has_requested_web_activation ? 'red' : 'green'}
              position="left"
            >
              <button
                onClick={() => !has_requested_web_activation && setOpenDev(true)}
                className={`fw500 unstyle-button ${!has_requested_web_activation ? 'c-pointer' : 'cursor-text opacity-5'} font-size-15 text-color`}
              >
                {!has_requested_web_activation ? 'Click to enable Web/E-Commerce payment for this account' : 'Web/E-Commerce payment enabled for this account'}
              </button>
            </Timeline.Item>
            <Timeline.Item
              dot={!sandbox_activated ? <ExclamationCircleOutlined className="font-size-17" /> : <CheckCircleOutlined className="font-size-17" />}
              color={!sandbox_activated ? 'red' : 'green'}
              position="left"
            >
              <button
                onClick={() => !sandbox_activated && has_requested_web_activation && updateSanboxPopup(true)}
                className={`fw500 unstyle-button ${!sandbox_activated && has_requested_web_activation ? 'c-pointer' : 'cursor-text opacity-5'} font-size-15 text-color`}
              >
                {!sandbox_activated ? 'Create a developer sandbox for testing web payments' : 'Developer sandbox account enabled for this account'}
              </button>
            </Timeline.Item>
          </Timeline>
        </div>
      )}
      <Spacer height={40} />
      {openDev && <DevPopup loadPopup={loadPopup} onClick={HANDLE_CLICK} onClose={() => setOpenDev(false)} />}
      {openSanboxPopup && <SandboxPopup onClick={enableSandbox} loadPopup={loadPopup} onClose={() => updateSanboxPopup(false)} />}
    </div>
  );
};

export const UnderReview = () => (
  <div className="fw400 text-color text-center font-size-15 " style={{ color: 'red' }}>
    You added this bank account recently. We take your security seriously, so we will be
    <br />
    calling you on the phone for confirmation before this new bank information can be used.
  </div>
);

export const MerchantSupport = ({ children }) => {
  return (
    <div className="p-relative support-gradient-padding d-flex flex-vertical">
      <Spacer height={50} />
      {children}
      <img src={cardOverlay} alt="" className="p-absolute full-height support-gradient-overlay" />
    </div>
  );
};

export const Webhook = () => {
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const webhook = useSelector((state) => state.merchant.merchantDetails['webhook']);
  const urlProps = useInput(webhook ?? '');
  const [loading, setLoading] = useState(false);
  let data = {
    webhook: urlProps.value,
  };

  const onClick = async () => {
    try {
      setLoading(true);
      await addWebhook(data, key);
      setLoading(false);
      openNotificationWithIcon('success', 'Success', 'Webhook added successfully');
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'Error adding webhook');
    }
  };
  return (
    <div className="p-relative full-height primary-bg-color webhook-wrap">
      <h3 className="fw500 font-size-17  text-color">Add a webhook endpoint</h3>
      <Spacer height={20} />
      <FormInput label="Webhook URL" {...urlProps} className="fw400 webhook-placeholder font-size-14" />
      <Spacer height={10} />
      <AppButton isBusy={loading} onClick={onClick} isActive={true} className="fw500 webhook-button primary-color font-size-13" name="Save Changes" />
      <Spacer height={80} />
    </div>
  );
};

const DevPopup = ({ onClose, onClick, text, loadPopup }) => {
  const ALT_TEXT = (
    <>
      <span className="fw400 font-size-17 text-color text-left">By selecting this option, you enable VPay Payment Gateway.</span>
      <br />
      <span className="fw400 font-size-17 text-color text-left">1,All payments made to this account must have to come from your website, otherwise it will be reversed.</span>
      <br />
      <span className="fw400 font-size-17 text-color text-left">2, NOTE: Web gateway transaction charges will apply henceforth.</span>
      <br />
      <span className="fw400 font-size-17 text-color text-left">Click "Continue" to proceed.</span>
    </>
  );
  return (
    <Popup width="27%" onClose={onClose}>
      <Spacer height={15} />
      <div className="d-flex flex-vertical align-center">
        <div className="d-flex align-center">
          <img src={warning} alt="" />
          <Spacer width={10} />
          <p className="fw500 no-margin font-size-18">Warning: Activate VPay Payment Gateway</p>
        </div>
        <Spacer height={15} />
        {ALT_TEXT}
        <Spacer height={20} />
        <AppButton isActive={true} onClick={onClick} isBusy={loadPopup} className="done-editing font-size-17 fw400 full-width primary-color" name="Continue" />
        <Spacer height={15} />
      </div>
    </Popup>
  );
};

const SandboxPopup = ({ onClose, onClick, text, loadPopup }) => {
  const { sandbox_activated = false } = useSelector((state) => state.merchant.merchantDetails);
  return (
    <Popup width="27%" onClose={onClose}>
      <Spacer height={15} />
      <div className="d-flex flex-vertical align-center">
        <Spacer height={15} />

        {!sandbox_activated && (
          <>
            <span className="fw400 font-size-17 text-color text-center">{text ?? `This will setup a sandbox environment for testing purposes.`}</span>
            <Spacer height={20} />
            <AppButton isActive={true} isBusy={loadPopup} onClick={onClick} className="done-editing font-size-17 fw400 full-width primary-color" name="Proceed" />
          </>
        )}
        {sandbox_activated && (
          <>
            <img style={{ width: '25%' }} src={success} alt="success" />
            <Spacer height={15} />
            <span className="fw400 font-size-17 text-color text-center">
              {text ?? `Visit `}
              <br />
              <a target="_blank" className="action-color" rel="noreferrer" href="https://dev.vpay.africa">
                dev.vpay.africa <br />
              </a>{' '}
              and sign in with your current login credentials to access your sandbox dashbaord.
            </span>
            <Spacer height={20} />
            <AppButton isActive={true} onClick={onClose} className="done-editing font-size-17 fw400 full-width primary-color" name="Proceed" />
          </>
        )}
        <Spacer height={15} />
      </div>
    </Popup>
  );
};

const Teams = () => {
  const [showRoles, setShowRoles] = useState(false);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);
  const [addRole, setAddRole] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showRemoveMember, setShowRemoveMember] = useState(false);
  const { showMemberDetailsPopup } = useSelector((state) => state?.roles);
  const { data: rolesData } = useQuery({ queryKey: 'getRolesList', queryFn: () => getMerchantRolesList(publickey), refetchInterval: true, refetchOnWindowFocus: true });

  const { data, isLoading, status } = useQuery({ queryKey: 'merchantTeamList', queryFn: () => getMerchantTeamList(publickey), refetchOnWindowFocus: true, refetchOnMount: true });

  const info = data?.data?.data?.users;

  return (
    <div className="team-wrapper primary-bg-color p-relative fade-in">
      {!showRoles && (
        <>
          <div className="full-width d-flex j-space-between align-center pt-2 roles-mobile-header no-padding-important-mobile">
            <div className="font-size13em fw500">Team</div>
            <div className="no-desktop-but-mobile">
              <Spacer height={10} />
            </div>
            <div className="d-flex j-space-between no-mobile">
              <AppButtonWithIcon
                icon={teamIcon}
                className="d-flex align-center app-button primary-bg-color button-nav-padding action-color btnOutline fade-in"
                name="View Roles"
                space={15}
                onClick={() => setShowRoles(true)}
                isActive={true}
                isBusy={false}
              />
              <Spacer width={10} />
              <AppButtonWithIcon
                className="d-flex align-center app-button action-color-2 button-nav-padding text-white fade-in"
                name="Invite Member"
                space={15}
                isActive={true}
                onClick={() => setAddRole(true)}
              />
            </div>
            <div className="no-desktop-but-mobile no-desktop-but-mobile-flex full-width">
              <AppButton
                className="full-width align-center app-button primary-bg-color m-button-nav-padding action-color btnOutline fade-in"
                name="View Roles"
                onClick={() => setShowRoles(true)}
                isActive={true}
                isBusy={false}
              />
              <Spacer width={10} />
              <AppButton className="full-width align-center app-button action-color-2 button-nav-padding text-white fade-in" name="Invite Member" isActive={true} onClick={() => setAddRole(true)} />
            </div>
          </div>

          <div>
            {status === 'loading' && (
              <div className="full-width full-height d-flex align-center j-center">
                <LoadingOutlined />
              </div>
            )}
            <Spacer height={20} className="no-desktop-but-mobile" />
            {info && info?.length > 0 ? (
              <>
                <TeamTableDesktop data={info} loading={isLoading} setShowUpdatePopup={setShowUpdatePopup} setShowRemoveMember={setShowRemoveMember} />
                <MobileTeamTableDesktop data={info} />
              </>
            ) : (
              <div>
                {!isLoading && (
                  <EmptyTeam
                    button={
                      <AppButton
                        className="d-flex align-center j-center app-button action-color-2 button-nav-padding text-white full-width fade-in"
                        name="Add New Member"
                        onClick={() => setAddRole(true)}
                        isActive={true}
                      />
                    }
                  />
                )}

                <Spacer height={15} />
              </div>
            )}
          </div>
        </>
      )}

      {showRoles && <TeamRoles back={setShowRoles} showAddMember={setAddRole} />}
      {addRole && <AddTeamMemberPopup back={setAddRole} rolesOptions={rolesData} showRoles={setShowRoles} />}
      {showUpdatePopup && <UpdateTeamMember back={setShowUpdatePopup} rolesOptions={rolesData} showRoles={setShowRoles} />}
      {showRemoveMember && <RemoveMember back={setShowRemoveMember} />}

      {showMemberDetailsPopup && <MobileMemberDetails setShowRemoveMember={setShowRemoveMember} />}
    </div>
  );
};
