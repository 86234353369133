import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState: {
    name: '',
    id: '',
    email: '',
    role: '',
    roles: [],
    showMemberDetailsPopup: false,
    uploadedImageUrl: '',
  },
  reducers: {
    setMemberName: (state, { payload }) => {
      state.name = payload;
    },
    setMemberId: (state, { payload }) => {
      state.id = payload;
    },
    setMemberEmail: (state, { payload }) => {
      state.email = payload;
    },
    setMemberRole: (state, { payload }) => {
      state.role = payload;
    },
    setTeamRoles: (state, { payload }) => {
      state.roles = payload;
    },
    resetMemberId: (state) => {
      state.id = '';
    },
    resetMemberEmail: (state) => {
      state.email = '';
    },
    resetMemberRole: (state) => {
      state.role = '';
    },
    resetTeamRoles: (state) => {
      state.roles = [];
    },
    setShowMemberDetails: (state, { payload }) => {
      state.showMemberDetailsPopup = payload;
    },
    setUploadedImageUrl: (state, { payload }) => {
      state.uploadedImageUrl = payload;
    },
    resetUploadedImageUrl: (state) => {
      state.uploadedImageUrl = '';
    },
  },
});

export const {
  setMemberEmail,
  setMemberRole,
  resetMemberEmail,
  resetMemberRole,
  resetTeamRoles,
  setTeamRoles,
  resetMemberId,
  setMemberId,
  setMemberName,
  setShowMemberDetails,
  setUploadedImageUrl,
  resetUploadedImageUrl,
} = rolesSlice.actions;
const rolesReducer = rolesSlice.reducer;
export default rolesReducer;
