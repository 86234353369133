import { MerchantActionTypes } from './merchant_types';

const INITIAL_STATE = {
  transactions: [],
  customers: [],
  loading: false,
  cashierBankDetailsLoading: false,
  isOpenCreateCashpoints: false,
  shouldCashpointModalOpen: false,
  isWithdrawPopupOpen: false,
  error: '',
  balance: null,
  successCodes: [],
  failureCodes: [],
  cashpoints: [],
  payouts: [],
  cashpointCustomers: {},
  currentCashpoint: null,
  merchantDetails: {},
  banks: [],
  cashierBanks: [],
  isBankDataVerified: false,
  verifiedBankName: '',
  openRedMessage: false,
  isSuggestionOpen: false,
  outboundFee: '',
  inboundFee: '',
  beneficiary: [],
  walletPrefix: '',
  shouldCreateMerchant: false,
  openMerchantQR: false,
  industries: [],
  eligibilityStatus: null,
  loanRequestInfo: null,
  loanInterestRates: null,
  aggregatorCheck: {},
  loans: [],
  loanCreditLimit: {},
  loanRequests: [],
  nextLoanRepayment: {},
  guarantors: [],
  alternateAccountRate: [],
  transferPin: '',
};

export const merchantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MerchantActionTypes.FETCH_TRANSACTIONS_START:
    case MerchantActionTypes.FETCH_CUSTOMERS_START:
    case MerchantActionTypes.FETCH_BALANCE_START:
    case MerchantActionTypes.FETCH_CASHPOINTS_START:
    case MerchantActionTypes.FETCH_PAYOUTS_START:
    case MerchantActionTypes.DELETE_CASHPOINT_START:
    case MerchantActionTypes.ADD_CASHPOINT_START:
    case MerchantActionTypes.UPDATE_BANK_START:
    case MerchantActionTypes.UPLOAD_CASHIER_PROFILE_PICTURE_START:
    case MerchantActionTypes.VERIFY_BANK_START:
    case MerchantActionTypes.ADD_MERCHANT_START:
    case MerchantActionTypes.CHECK_LOAN_ELIGIBILITY_START:
    case MerchantActionTypes.SUBMIT_LOAN_REQUEST_START:
    case MerchantActionTypes.GET_LOAN_INTEREST_RATES_START:
    case MerchantActionTypes.FETCH_LOAN_HISTORY_START:
    case MerchantActionTypes.FETCH_WARRANTER_LOAN_REQUESTS_START:
    case MerchantActionTypes.FETCH_LOAN_CREDIT_LIMIT_START:
    case MerchantActionTypes.FETCH_NEXT_LOAN_REPAYMENT_START:
    case MerchantActionTypes.FETCH_GUARANTOR_TYPES_START:
    case MerchantActionTypes.FETCH_ALTERNATE_ACCOUNT_FEES_START:
      return {
        ...state,
        loading: true,
      };
    case MerchantActionTypes.CHECK_LOAN_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        eligibilityStatus: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.SUBMIT_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        loanRequestInfo: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.GET_LOAN_INTEREST_RATES_SUCCESS:
      return {
        ...state,
        loanInterestRates: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.VERIFY_AGGREGATOR_CODE_SUCCESS:
      return {
        ...state,
        aggregatorCheck: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_LOAN_HISTORY_SUCCESS:
      return {
        ...state,
        loans: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_WARRANTER_LOAN_REQUESTS_SUCCESS:
      return {
        ...state,
        loanRequests: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_LOAN_CREDIT_LIMIT_SUCCESS:
      return {
        ...state,
        loanCreditLimit: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_NEXT_LOAN_REPAYMENT_SUCCESS:
      return {
        ...state,
        nextLoanRepayment: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_GUARANTOR_TYPES_SUCCESS:
      return {
        ...state,
        guarantors: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.VERIFY_CASHIER_BANK_START:
    case MerchantActionTypes.ADD_CASHIER_BANK_DETAILS_START:
      return {
        ...state,
        cashierBankDetailsLoading: true,
      };
    case MerchantActionTypes.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
        loading: false,
        error: '',
      };
    case MerchantActionTypes.FETCH_CASHPOINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        cashpoints: action.payload,
        currentCashpoint: action.payload[0] ?? null,
      };
    case MerchantActionTypes.FETCH_SUCCESS_CODES:
      return {
        ...state,
        successCodes: action.payload,
      };
    case MerchantActionTypes.FETCH_FAILURE_CODES:
      return {
        ...state,
        failureCodes: action.payload,
      };
    case MerchantActionTypes.FETCH_PAYOUTS_SUCCESS:
      return {
        ...state,
        payouts: action.payload,
        loading: false,
      };
    case MerchantActionTypes.FETCH_TRANSACTIONS_FAILURE:
    case MerchantActionTypes.FETCH_CUSTOMERS_FAILURE:
    case MerchantActionTypes.FETCH_BALANCE_FAILURE:
    case MerchantActionTypes.FETCH_CASHPOINTS_FAILURE:
    case MerchantActionTypes.FETCH_PAYOUTS_FAILURE:
    case MerchantActionTypes.ADD_CASHPOINT_FAILURE:
    case MerchantActionTypes.DELETE_CASHPOINT_FAILURE:
    case MerchantActionTypes.CHECK_LOAN_ELIGIBILITY_FAILURE:
    case MerchantActionTypes.SUBMIT_LOAN_REQUEST_FAILURE:
    case MerchantActionTypes.GET_LOAN_INTEREST_RATES_FAILURE:
    case MerchantActionTypes.VERIFY_AGGREGATOR_CODE_FAILURE:
    case MerchantActionTypes.FETCH_LOAN_HISTORY_FAILURE:
    case MerchantActionTypes.FETCH_LOAN_CREDIT_LIMIT_FAILURE:
    case MerchantActionTypes.FETCH_WARRANTER_LOAN_REQUESTS_FAILURE:
    case MerchantActionTypes.WARRANTER_LOAN_APPROVAL_FAILURE:
    case MerchantActionTypes.FETCH_NEXT_LOAN_REPAYMENT_FAILURE:
    case MerchantActionTypes.FETCH_GUARANTOR_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'OPEN_CREATE_CASHPOINTS':
      return {
        ...state,
        isOpenCreateCashpoints: true,
      };
    case 'CLOSE_CREATE_CASHPOINTS':
      return {
        ...state,
        isOpenCreateCashpoints: false,
      };
    case MerchantActionTypes.FETCH_CASHPOINT_CUSTOMER_FAILURE:
      return {
        ...state,
        cashpointCustomers: {},
      };
    case MerchantActionTypes.FETCH_CASHPOINT_CUSTOMER_SUCCESS:
      return {
        ...state,
        cashpointCustomers: action.payload,
      };
    case 'OPEN_CASHPOINT_POPUP':
      return {
        ...state,
        shouldCashpointModalOpen: true,
      };
    case 'CLOSE_CASHPOINT_POPUP':
      return {
        ...state,
        shouldCashpointModalOpen: false,
      };
    case 'OPEN_WITHDRAW_POPUP':
      return {
        ...state,
        isWithdrawPopupOpen: true,
      };
    case 'CLOSE_WITHDRAW_POPUP':
      return {
        ...state,
        isWithdrawPopupOpen: false,
      };
    case 'OPEN_RED_MESSAGE':
      return {
        ...state,
        openRedMessage: true,
      };
    case 'CLOSE_RED_MESSAGE':
      return {
        ...state,
        openRedMessage: false,
      };
    case 'OPEN_SUGGESTION_BOX':
      return {
        ...state,
        isSuggestionOpen: true,
      };
    case 'CLOSE_SUGGESTION_BOX':
      return {
        ...state,
        isSuggestionOpen: false,
      };
    case 'OPEN_CREATE_MERCHANT':
      return {
        ...state,
        shouldCreateMerchant: true,
      };
    case 'CLOSE_CREATE_MERCHANT':
      return {
        ...state,
        shouldCreateMerchant: false,
      };
    case 'OPEN_QR':
      return {
        ...state,
        openMerchantQR: true,
      };
    case 'CLOSE_QR':
      return {
        ...state,
        openMerchantQR: false,
      };
    case 'FETCH_INDUSTRY_LIST':
      return {
        ...state,
        industries: action.payload,
      };
    case MerchantActionTypes.UPDATE_CURRENT_CASHIER:
      return {
        ...state,
        currentCashpoint: state.cashpoints.find((c) => c._id === action.payload),
      };
    case MerchantActionTypes.DELETE_CASHIER_SUCCESS:
    case MerchantActionTypes.UPDATE_BANK_SUCCESS:
    case MerchantActionTypes.UPDATE_BANK_FAILURE:
    case MerchantActionTypes.UPLOAD_CASHIER_PROFILE_PICTURE_SUCCESS:
    case MerchantActionTypes.UPLOAD_CASHIER_PROFILE_PICTURE_FAILURE:
    case MerchantActionTypes.ADD_MERCHANT_FAILURE:
    case MerchantActionTypes.ADD_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MerchantActionTypes.VERIFY_CASHIER_BANK_SUCCESS:
    case MerchantActionTypes.VERIFY_CASHIER_BANK_FAILURE:
    case MerchantActionTypes.ADD_CASHIER_BANK_DETAILS_SUCCESS:
    case MerchantActionTypes.ADD_CASHIER_BANK_DETAILS_FAILURE:
      return {
        ...state,
        cashierBankDetailsLoading: false,
      };
    case MerchantActionTypes.FETCH_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantDetails: action.payload,
      };
    case MerchantActionTypes.FETCH_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.payload,
      };
    case MerchantActionTypes.FETCH_CASHIER_BANKS_SUCCESS:
      return {
        ...state,
        cashierBanks: action.payload,
      };
    case MerchantActionTypes.VERIFY_BANK_SUCCESS:
      return {
        ...state,
        isBankDataVerified: true,
        verifiedBankName: action.payload,
        loading: false,
      };
    case MerchantActionTypes.VERIFY_BANK_FAILURE:
      return {
        ...state,
        isBankDataVerified: false,
        verifiedBankName: '',
        loading: false,
      };
    case MerchantActionTypes.FETCH_FEE_SUCCESS:
      return {
        ...state,
        inboundFee: action.payload[state.merchantDetails.classification].inbound.fee,
        outboundFee: action.payload[state.merchantDetails.classification].outbound.fee,
      };
    case MerchantActionTypes.FETCH_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        beneficiary: action.payload,
      };
    case 'FETCH_PREFIX_SUCCESS':
      return {
        ...state,
        walletPrefix: action.payload,
      };

    case MerchantActionTypes.FETCH_ALTERNATE_ACCOUNT_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        alternateAccountRate: action.payload,
      };
    case MerchantActionTypes.FETCH_ALTERNATE_ACCOUNT_FEES_FAILURE:
      return {
        ...state,
        loading: false,
        alternateAccountRate: action.payload,
      };
    case MerchantActionTypes.TEMP_STORE_PIN:
      return {
        ...state,
        transferPin: action.payload,
      };
    case MerchantActionTypes.CLEAR_STORED_PIN:
      return {
        ...state,
        transferPin: '',
      };

    default:
      return state;
  }
};
