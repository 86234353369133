import { LoadingOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { saveAs } from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import AppButton from '../../components/app_button/app_button';
import { FormDropdown } from '../../components/form_dropdown/form_dropdown';
import { FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import Popup from '../../components/popup/popup';
import TransactionIdContext from '../../contexts/transactionIdContext';
import { fetchBalance, fetchPayouts, fetchTransactions } from '../../redux/merchant/merchant_actions';
import { addBeneficiary, getRecieptPdf, verifyBankDetails, walletTransfer } from '../../services/apiSevices';
import { transferFormValidator } from '../../utils/validation_rules/tranfer_form.validate';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import './index.css';
import { InitialPinSetUp } from './profile';
import { getBankCode, getBankName } from './settings';
import { ConfirmWithdraw, PinPopup, SuccessPopup } from './withdraw';

export const Transfer = ({ setIsTransferPopupOpen }) => {
  const banks = useSelector((state) => state.merchant.banks);
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  const { transferPin } = useSelector((state) => state?.merchant);
  const [position, setPosition] = useState(1);
  const prefix = useSelector((state) => state.merchant.walletPrefix);
  const outboundFee = useSelector((state) => state.merchant.outboundFee);
  const [bankName, setBankName] = useState('');
  const [shouldSetPin, setShouldSetPin] = useState(false);
  const [verifiedBankName, setVerifiedBankName] = useState('');
  const [isBankDataVerified, setIsBankDataVerified] = useState(false);
  const [flagName, setFlagName] = useState(false);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const [formDetails, setFormDetails] = useState({
    amount: '',
    accountNumber: '',
    description: '',
  });

  const dispatch = useDispatch();

  const [showSwitch, setShowSwitch] = useState(true);
  const [loading, setLoading] = useState(false);
  const transactionId = useContext(TransactionIdContext);

  const onClose = () => {
    setIsTransferPopupOpen(false);
    setPosition(0);
    dispatch(fetchBalance(id));
    dispatch(fetchPayouts(id));
    dispatch(fetchTransactions(id));
  };

  const onChangeBankChange = (e) => {
    setBankName(e);
  };

  const data = {
    amount: formDetails.amount,
    accountName: verifiedBankName,
    accountNumber: formDetails.accountNumber,
    bankName,
    fee: outboundFee,
    description: formDetails.description,
  };

  const details = {
    nuban: data.accountNumber,
    amount: data.amount,
    bank_code: getBankCode(bankName, banks),
    remark: data.description,
    transaction_ref: prefix?.concat(uuid()),
    pin: transferPin && transferPin,
  };

  const goForward = () => {
    setPosition(position + 1);
  };

  const onConfirmClick = () => {
    if (!merchantDetails.issetpin && !merchantDetails.pin) {
      setShouldSetPin(true);
    } else {
      setShouldSetPin(false);
    }

    goForward();
  };

  const handleDownloadReceiptClick = async () => {
    try {
      setLoading(true);
      const res = await getRecieptPdf(id, transactionId.transactionId);
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdfBlob, 'VPay Transaction Receipt');
      setLoading(false);
      openNotificationWithIcon('success', 'Success', 'Receipt Successfully Downloaded');
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const goPosition = () => {
    switch (position) {
      case 1:
        return (
          <TabsPopup
            formDetails={formDetails}
            setFormDetails={setFormDetails}
            onChangeBankChange={onChangeBankChange}
            onClose={onClose}
            isBankDataVerified={isBankDataVerified}
            setIsBankDataVerified={setIsBankDataVerified}
            setShowSwitch={setShowSwitch}
            setBankName={setBankName}
            bankName={bankName}
            verifiedBankName={verifiedBankName}
            setVerifiedBankName={setVerifiedBankName}
            showSwitch={showSwitch}
            flagName={flagName}
            setFlagName={setFlagName}
            setPosition={setPosition}
          />
        );
      case 2:
        return <ConfirmWithdraw bankCode={getBankCode(bankName, banks)} onClose={onClose} info={data} goBack={() => setPosition(position - 1)} onConfirmClick={onConfirmClick} flag={false} />;
      case 3:
        return shouldSetPin ? (
          <InitialPinSetUp setShouldSetPin={setShouldSetPin} onClose={onClose} />
        ) : (
          <PinPopup onClose={onClose} goForward={() => setPosition(4)} apiCall={() => walletTransfer(key, details)} />
        );
      case 4:
        return (
          <SuccessPopup type="Transfer" bottomText="Your transfer request has been processed successfully" onClose={onClose} isBusy={loading} onDownloadReceiptClick={handleDownloadReceiptClick} />
        );
      default:
        return null;
    }
  };

  return <>{goPosition()}</>;
};

export const TabsPopup = ({
  onClose,
  isBankDataVerified,
  setFlagName,
  flagName,
  setIsBankDataVerified,
  showSwitch,
  setPosition,
  setShowSwitch,
  verifiedBankName,
  setVerifiedBankName,
  setBankName,
  bankName,
  onChangeBankChange,
  formDetails,
  setFormDetails,
}) => {
  return (
    <GenericTabsPopup
      defaultPosition={2}
      title="Send Money"
      onClose={onClose}
      firstText="Saved Beneficiary"
      secondText={!flagName ? 'New Beneficiary' : 'Selected Beneficiary'}
      secondComponent={(_) => (
        <NewBeneficiary
          onChangeBankChange={onChangeBankChange}
          setPosition={setPosition}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          isBankDataVerified={isBankDataVerified}
          setIsBankDataVerified={setIsBankDataVerified}
          bankName={bankName}
          verifiedBankName={verifiedBankName}
          setVerifiedBankName={setVerifiedBankName}
          showSwitch={showSwitch}
          setFlagName={setFlagName}
          setShowSwitch={setShowSwitch}
        />
      )}
      firstComponent={(_, setActiveTab) => (
        <SavedBeneficiary
          setFlagName={setFlagName}
          setShowSwitch={setShowSwitch}
          setBankName={setBankName}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          setIsBankDataVerified={setIsBankDataVerified}
          setVerifiedBankName={setVerifiedBankName}
          setActiveTab={setActiveTab}
          setPosition={setPosition}
        />
      )}
    />
  );
};

export const GenericTabsPopup = ({ defaultPosition, firstComponent, secondComponent, firstText, secondText, title, firstTitle, secondTitle, onClose }) => {
  const [activeTab, setActiveTab] = useState(defaultPosition);
  const mainTitle = title || (activeTab === 1 ? firstTitle : secondTitle);
  return (
    <Popup title={mainTitle} onClose={onClose}>
      <Spacer height={30} />
      <TabButtons setActiveTab={setActiveTab} activeTab={activeTab} firstText={firstText} secondText={secondText} />
      <Spacer height={30} />
      {activeTab === 1 && firstComponent !== undefined && firstComponent(activeTab, setActiveTab)}
      {activeTab === 2 && secondComponent !== undefined && secondComponent(activeTab, setActiveTab)}
    </Popup>
  );
};

export const GenericMultipleTabsPopup = ({ defaultPosition, firstComponent, secondComponent, thirdComponent, firstText, secondText, title, firstTitle, thirdText, secondTitle, onClose }) => {
  const [activeTab, setActiveTab] = useState(defaultPosition);
  const mainTitle = title || (activeTab === 1 ? firstTitle : secondTitle);
  // switch (activeTab) {
  //   case 1:

  //     break;

  //   default:
  //     break;
  // }
  return (
    <Popup title={mainTitle} onClose={onClose}>
      <Spacer height={30} />
      <TabButtons setActiveTab={setActiveTab} activeTab={activeTab} firstText={firstText} secondText={secondText} thirdText={thirdText} />
      <Spacer height={30} />
      {activeTab === 1 && firstComponent !== undefined && firstComponent(activeTab, setActiveTab)}
      {activeTab === 2 && secondComponent !== undefined && secondComponent(activeTab, setActiveTab)}
      {activeTab === 3 && thirdComponent !== undefined && thirdComponent(activeTab, setActiveTab)}
    </Popup>
  );
};

export const TabButtons = ({ setActiveTab, activeTab, firstText, secondText, thirdText, children }) => {
  return (
    <>
      <div className="d-flex align-center j-space-between full-width">
        {firstText && (
          <button type="button" className={`tab-button fw400 font-size-16 ${activeTab === 1 ? 'active-button-tab' : ''}`} onClick={() => setActiveTab(1)}>
            {firstText}
          </button>
        )}
        {secondText && (
          <button type="button" className={`tab-button fw400 font-size-16 ${activeTab === 2 ? 'active-button-tab' : ''}`} onClick={() => setActiveTab(2)}>
            {secondText}
          </button>
        )}
        {thirdText && (
          <button type="button" className={`tab-button fw400 font-size-16 ${activeTab === 3 ? 'active-button-tab' : ''}`} onClick={() => setActiveTab(3)}>
            {thirdText}
          </button>
        )}
      </div>
      {children && (
        <div className="d-flex flex-vertical full-width">
          <Spacer height={20} />
          {children}
        </div>
      )}
    </>
  );
};

export const NewBeneficiary = ({
  setPosition,
  setShowSwitch,
  setFlagName,
  isBankDataVerified,
  setIsBankDataVerified,
  showSwitch,
  onChangeBankChange,
  verifiedBankName,
  setVerifiedBankName,
  formDetails,
  setFormDetails,
  bankName,
}) => {
  const banks = useSelector((state) => state.merchant.banks);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const mappedBanks = banks.map((bank) => bank.name);
  const [error, setError] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [checked, setChecked] = useState(false);

  let beneficiaryData = {
    accountnumber: formDetails.accountNumber,
    accountname: verifiedBankName,
    bankcode: getBankCode(bankName, banks),
    merchant: id,
  };

  const onClick = async () => {
    const error = transferFormValidator(formDetails);
    setError(error);
    if (isBankDataVerified && formDetails.amount && bankName) {
      setPosition(2);

      if (checked) {
        try {
          await addBeneficiary(publickey, beneficiaryData);
        } catch (error) {
          openNotificationWithIcon('error', 'Error', error.response?.data?.message);
        }
      }
    }
  };
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  const handleSwitchToggle = () => {
    setChecked((checked) => !checked);
  };

  const resetForm = () => {
    setFormDetails({
      amount: '',
      accountNumber: '',
      description: '',
    });
    setShowSwitch(true);
    setVerifiedBankName('');
    setIsBankDataVerified(false);
    setFlagName(false);
  };

  useEffect(() => {
    const verify = async () => {
      if (bankName && formDetails.accountNumber) {
        if (formDetails.accountNumber.length === 10) {
          const data = {
            accountnumber: formDetails.accountNumber,
            bankcode: getBankCode(bankName, banks),
          };
          try {
            setIsBusy(true);
            const res = await verifyBankDetails(data);
            if (res.data.accountname === '') {
              setIsBusy(false);
              return openNotificationWithIcon('error', 'Error', 'Account not found');
            }
            setVerifiedBankName(res.data.accountname);
            setIsBankDataVerified(true);
            setIsBusy(false);
          } catch (error) {
            setIsBusy(false);
            setIsBankDataVerified(false);
            openNotificationWithIcon('error', 'Error', error?.response?.data?.message ?? 'An error occured');
          }
        }
      }
    };
    verify();
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankName, formDetails.accountNumber]);

  return (
    <div className="p-relative overflow-scroll full-height">
      <FormDropdown onChange={onChangeBankChange} defaultValue={bankName} className="important-full-width" label="Account Provider" options={mappedBanks} />
      <FormInput name="accountNumber" error={error} onChange={handleInputChange} value={formDetails.accountNumber} label="Account Number" className="important-full-width" />
      {isBankDataVerified && (
        <>
          <span className="important-full-width nav-item-color fw400 ">{verifiedBankName}</span>
          <Spacer height={5} />
        </>
      )}
      {isBusy && !isBankDataVerified && <LoadingOutlined />}
      <FormInput name="amount" error={error} onChange={handleInputChange} value={formDetails.amount} label="Amount" className="important-full-width" />
      <FormInput name="description" onChange={handleInputChange} value={formDetails.description} label="Remark (Optional)" className="important-full-width" />
      {showSwitch && (
        <div className="full-width d-flex align-center">
          <span className="text-color fw400 font-size-16 opacity-5">Save Beneficiary?</span>
          <Spacer width={20} />
          <Switch checked={checked} onChange={handleSwitchToggle} />
        </div>
      )}
      <Spacer height={20} />
      <div className="d-flex align-center j-space-between">
        {bankName && formDetails.accountNumber && <AppButton onClick={resetForm} isActive={true} className="font-size-16  b-tn-padding c-btn fw400 text-color" name="Clear" />}
        <AppButton onClick={onClick} isActive={true} className="font-size-16  b-tn-padding withdraw-button fw400 primary-color" name="Continue" />
      </div>
    </div>
  );
};

export const AlignRight = ({ children }) => {
  return <div className="d-flex full-width j-end">{children}</div>;
};

export const SavedBeneficiary = ({ setActiveTab, setFlagName, setIsBankDataVerified, setVerifiedBankName, formDetails, setFormDetails, setBankName, setShowSwitch }) => {
  const banks = useSelector((state) => state.merchant.banks);
  const beneficiaries = useSelector((state) => state.merchant.beneficiary);

  return (
    <div className="beneficiary-container">
      {beneficiaries &&
        beneficiaries.map((ben, idx) => (
          <Beneficiary
            setFlagName={setFlagName}
            setIsBankDataVerified={setIsBankDataVerified}
            setVerifiedBankName={setVerifiedBankName}
            setShowSwitch={setShowSwitch}
            setBankName={setBankName}
            formDetails={formDetails}
            setFormDetails={setFormDetails}
            setActiveTab={setActiveTab}
            ben={ben}
            name={ben.name}
            bank={getBankName(ben.code, banks)}
            number={ben.number}
            key={idx}
          />
        ))}
    </div>
  );
};

export const Beneficiary = ({ name, bank, number, setFlagName, setIsBankDataVerified, ben, setActiveTab, setShowSwitch, setFormDetails, formDetails, setBankName }) => {
  const banks = useSelector((state) => state.merchant.banks);
  const onClick = () => {
    setFormDetails({
      ...formDetails,
      accountNumber: ben.number,
    });
    setBankName(getBankName(ben.code, banks));
    setActiveTab(2);
    setIsBankDataVerified(false);
    setShowSwitch(false);
    setFlagName(true);
  };
  return (
    <div onClick={onClick} className="c-pointer" style={{ width: 'fit-content' }}>
      <p className="no-margin line-height17 fw400 text-color font-size-17"> {name}</p>
      <Spacer height={5} />
      <span className="text-color opacity-5 font-size-15">{`${bank} - ${number}`}</span>
      <Spacer height={30} />
    </div>
  );
};
